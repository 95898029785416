<template>
    <!--<img alt="Vue logo" src="./assets/logo.png">-->
    <!--<HelloWorld msg="Welcome to Your Vue.js + TypeScript App"/>-->
    <section class="section">
        <div class="container">

            <div class="notification is-danger">
                You're doing this at your own risk. Always back up your save files.
            </div>
            
            <!-- HEADER -->
            <div class="block">
                <p class="title">fch-editor <span class="has-text-grey-light">{{ APP_VERSION }}</span></p>
                <p class="subtitle has-text-grey-light">
                    Valheim Character Editor
                </p>
            </div>

            <!-- FILE INPUT -->
            <div class="block" v-if="!playerData.dataVersions || !playerData.dataVersions.player">
                <div class="field">
                    <div class="control">
                        <input class="input" type="file" @change="handleFile" accept=".fch" aria-label="Character file" />
                    </div>
                    <p class="help" v-if="!playerData.playerName">
                        Windows: <code style="user-select: all">%userprofile%\AppData\LocalLow\IronGate\Valheim\characters\</code><br>
                        Linux: <code style="user-select: all">~/.config/unity3d/IronGate/Valheim/characters/</code>
                    </p>
                </div>
            </div>
            
            <!-- CHEATS -->
            <div class="block">
                <div class="field">
                    <label class="checkbox has-text-weight-bold">
                        <input type="checkbox" v-model="imacheater" /> Show cheat tools
                    </label>
                </div>
            </div>

            <!-- WARNING -->
            <article class="message is-danger" v-if="unsupportedVersion">
                <div class="message-header">
                    <p>Danger</p>
                    
                </div>
                <div class="message-body content">
                    The version of your save file might be unsupported. Do this at your own risk.<br>
                    <ul>
                        <li>Player is {{ playerData.dataVersions.player }}, expected {{ supportedVersion.player }}</li>
                        <li v-if="playerData.worldData">
                            <ul>
                                <li v-for="(world, i) in playerData.worldData" :key="world">
                                    MiniMap #{{ i }} is {{ world.miniMapMapversion }}, expected {{ supportedVersion.miniMap }}
                                </li>
                            </ul>
                        </li>
                        <li>PlayerData is {{ playerData.dataVersions.playerData }}, expected {{ supportedVersion.playerData }}</li>
                        <li>Inventory is {{ playerData.dataVersions.inventory }}, expected {{ supportedVersion.inventory }}</li>
                        <li>Skills is {{ playerData.dataVersions.skills }}, expected {{ supportedVersion.skills }}</li>
                    </ul>
                </div>
            </article>

            <!-- MAIN EDITOR -->
            <div v-if="playerData.playerName">
                
                <!-- PLAYER INFO -->
                <div class="block">
                    <div class="content">
                        <p class="title">{{ playerData.playerName }}</p>
                        <ul>
                            <li><strong>PVP kills:</strong> {{ playerData.playerKills }}</li>
                            <li><strong>Deaths:</strong> {{ playerData.playerDeaths }}</li>
                            <li><strong>Crafts:</strong> {{ playerData.playerCrafts }}</li>
                            <li><strong>Builds:</strong> {{ playerData.playerBuilds }}</li>
                            <li><strong>Guardian:</strong> {{ playerData.guardianPower }}</li>
                            <li><strong>Data size:</strong> {{ new Intl.NumberFormat().format(playerData.saveDataLength || 0) }}</li>
                            <li><strong>Player ID:</strong> {{ playerData.playerId }}</li>
                            <li>
                                <strong>Visited biomes:</strong>
                                <ul>
                                    <li v-for="biome_id in playerData.knownBiomes" :key="biome_id">{{ biomeNames[biome_id] || biome_id }}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Data versions:</strong>
                                <ul>
                                    <li v-for="(v, k) in playerData.dataVersions" :key="k">{{ k }}: {{ v }}</li>
                                    <template v-if="playerData.worldData">
                                        <li v-for="(world, i) in playerData.worldData" :key="world">Minimap #{{ i }}: {{ world.miniMapMapversion }}</li>
                                    </template>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <!-- PLAYER EDITOR -->
                <details class="block" open>
                    <summary class="title">Player</summary>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">Beard</label>
                                <div class="control">
                                    <!--<input class="input" type="text" v-model="playerData.beard">-->
                                    <div class="select is-fullwidth">
                                        <select v-model="playerData.beard">
                                            <option v-for="item in playerBeards" :key="item">{{ item }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Hair</label>
                                <div class="control">
                                    <!--<input class="input" type="text" v-model="playerData.hair">-->
                                    <div class="select is-fullwidth">
                                        <select v-model="playerData.hair">
                                            <option v-for="item in playerHairs" :key="item">{{ item }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="field">
                                <label class="label">Hair colour</label>
                                <div class="control">
                                    <input class="input" type="color" v-model="hairColour">
                                </div>
                            </div>
                            
                            <div class="field">
                                <label class="label">Skin colour</label>
                                <div class="control">
                                    <input class="input" type="color" v-model="skinColour">
                                </div>
                            </div>
                        </div>
                        <div class="column">

                            <div class="field">
                                <label class="label">Name <strong class="has-text-danger">(untested)</strong></label>
                                <div class="control">
                                    <input class="input" type="text" v-model="playerData.playerName">
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Model</label>
                                <div class="control">
                                    <div class="select is-fullwidth">
                                        <select v-model="playerData.modelIndex">
                                            <option value="0">Male</option>
                                            <option value="1">Female</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="field" v-if="imacheater">
                                <label class="label">Health</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="playerData.playerHealth">
                                </div>
                            </div>

                            <div class="field" v-if="imacheater">
                                <label class="label">Max health</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="playerData.playerMaxHealth">
                                </div>
                            </div>

                        </div>

                    </div>
                </details>

                <!-- INVENTORY -->
                <details class="block" v-if="imacheater" open>
                    <summary class="title">Inventory</summary>
                    <table class="table is-striped is-bordered is-fullwidth inventory-table">
                        <tr v-for="(row, pos_y) in formattedInventory" :key="pos_y">
                            <td v-for="(cell, pos_x) in row" :key="pos_x" :class="{ 'is-selected': cell.equipped }">
                                <!-- inventory slot full -->
                                <div class="inventory-slot-full" v-if="!cell.empty" @click="cell.isEditing = !cell.isEditing">
                                    <p :class="{ 'title': true, 'is-6': true, 'has-text-danger': itemConfig[cell.name].m_itemData.m_shared.m_dlc }">
                                        {{ itemConfig[cell.name].m_itemData.m_shared.m_name_EN }}
                                    </p>
                                    
                                    <p class="subtitle is-6 has-text-grey-light mb-2" v-if="cell.amount > 1 || (itemConfig[cell.name] && itemConfig[cell.name].m_itemData.m_shared.m_maxStackSize !== undefined && itemConfig[cell.name].m_itemData.m_shared.m_maxStackSize > 1)">
                                        {{ cell.amount }}/{{ itemConfig[cell.name].m_itemData.m_shared.m_maxStackSize || '?' }}
                                    </p>
                                    
                                    <div class="tags">
                                        <span class="tag" title="Quality">⭐ {{ cell.quality }}</span>
                                        <span class="tag" title="Durability">🔨 {{ cell.durability.toFixed(1) }}</span>
                                        <span class="tag" title="Weight">📦 {{ (itemConfig[cell.name].m_itemData.m_shared.m_weight * cell.amount).toFixed(1) }}</span>
                                    </div>
                                    <div class="tooltip">
                                        <p>
                                            {{ itemConfig[cell.name].m_itemData.m_shared.m_description_EN }}
                                        </p>
                                        <ul>
                                            <li><strong><em>{{ itemConfig[cell.name].m_itemData.m_shared.m_itemType }}</em></strong></li>
                                            <li v-if="cell.crafterName"><strong>Crafted by:</strong> {{ cell.crafterName }}</li>
                                            <li><strong>Weight:</strong> {{ (itemConfig[cell.name].m_itemData.m_shared.m_weight * cell.amount).toFixed(1) }}</li>
                                            <li><strong>Armor:</strong> {{ itemConfig[cell.name].m_itemData.m_shared.m_armor }}</li>
                                            <li><strong>Block power:</strong> {{ itemConfig[cell.name].m_itemData.m_shared.m_blockPowerPerLevel * cell.quality }}</li>
                                            <li><strong>Parry force:</strong> {{ itemConfig[cell.name].m_itemData.m_shared.m_deflectionForce * cell.quality }}x</li>
                                            <li><strong>Parry bonus:</strong> {{ itemConfig[cell.name].m_itemData.m_shared.m_timedBlockBonus }}x</li>
                                            <li><strong>Knockback:</strong> {{ itemConfig[cell.name].m_itemData.m_shared.m_attackForce }}</li>
                                            <li><strong>Backstab:</strong> x{{ itemConfig[cell.name].m_itemData.m_shared.m_backstabBonus }}</li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- inventory slot empty -->
                                <div class="inventory-slot-empty" v-if="cell.empty && !cell.isEditing && !copyInventoryItem">
                                    <button class="button is-small" @click="makeNewInventoryItem(parseInt(pos_y), pos_x, true);">Add</button>
                                </div>

                                <!-- inventory slot empty -->
                                <div class="inventory-slot-empty" v-if="cell.empty && !cell.isEditing && copyInventoryItem">
                                    <button class="button is-info is-small" @click="pasteInventoryItem(parseInt(pos_y), pos_x);">Paste</button>
                                </div>

                                <!-- inventory slot editing -->
                                <div class="modal is-active" v-if="cell.isEditing">
                                    <div class="modal-background" @click="cell.isEditing = false;"></div>
                                    <div class="modal-content">
                                        <div class="card">
                                            <div class="card-content">
                                                <p class="title">
                                                    {{ itemConfig[cell.name].m_itemData.m_shared.m_name_EN }} <span class="has-text-grey-light">x{{ cell.amount }}</span>
                                                </p>
                                                <p class="subtitle">{{ cell.name }}</p>
                                                <div class="field">
                                                    <div class="control">
                   
                                                            <Multiselect
                                                                v-model="cell.name"
                                                                searchable
                                                                :options="pickableInventoryItemsSorted"
                                                                @change="cell.quality = 1; cell.amount = 1; cell.equipped = false; cell.durability = itemConfig[cell.name].m_itemData.m_shared.m_durabilityPerLevel"
                                                            >
                                                                <template v-slot:singlelabel="{ value }">
                                                                    <div class="multiselect-single-label">
                                                                        <div>{{ value.label }}</div>
                                                                        <div class="has-text-grey">&nbsp;({{ value.data.name }})</div>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:option="{ option }">
                                                                    <div>{{ option.label }}</div>
                                                                    <div class="has-text-grey is-flex-grow-1 has-text-right">{{ option.data.name }}</div>
                                                                </template>
                                                            </Multiselect>
                                                            
                                                            <!--<v-select :options="pickableInventoryItemsSorted" label="name"></v-select>-->
                                                        
                                                    </div>
                                                </div>
                                                <div class="field" v-if="itemConfig[cell.name].m_itemData.m_shared.m_maxStackSize !== 1">
                                                    <label class="label">Amount</label>
                                                    <div class="control">
                                                        <!--<input class="input" type="number" v-model="cell.amount" min="1" :max="itemConfig[cell.name].m_itemData.m_shared.m_maxStackSize">-->
                                                        <input
                                                            class="slider is-fullwidth is-medium has-output"
                                                            type="range"
                                                            v-model.number="cell.amount"
                                                            min="1"
                                                            :max="itemConfig[cell.name].m_itemData.m_shared.m_maxStackSize"
                                                        >
                                                        <output>{{ cell.amount }}</output>
                                                    </div>
                                                </div>
                                                <div class="field" v-if="itemConfig[cell.name].m_itemData.m_shared.m_dlc">
                                                    <strong class="has-text-danger">Requires DLC: {{ itemConfig[cell.name].m_itemData.m_shared.m_dlc }}</strong>
                                                </div>
                                                <div class="field" v-if="itemConfig[cell.name].m_itemData.m_shared.m_maxQuality > 1">
                                                    <label class="label">Quality / Level</label>
                                                    <div class="control">
                                                        <!--<input class="input" type="number" v-model="cell.quality" min="1" :max="itemConfig[cell.name].m_itemData.m_shared.m_maxQuality">-->
                                                        <input
                                                            class="slider is-fullwidth is-medium has-output"
                                                            type="range"
                                                            v-model.number="cell.quality"
                                                            min="1"
                                                            :max="itemConfig[cell.name].m_itemData.m_shared.m_maxQuality"
                                                            @change="cell.durability = cell.quality * itemConfig[cell.name].m_itemData.m_shared.m_durabilityPerLevel"
                                                        >
                                                        <output for="sliderWithValue">{{ cell.quality }}</output>
                                                    </div>
                                                </div>
                                                <div class="field" v-if="itemConfig[cell.name].m_itemData.m_shared.m_useDurability">
                                                    <label class="label">Durability</label>
                                                    <div class="control">
                                                        <!--<input class="input" type="number" v-model.number="cell.durability" min="0" :max="cell.quality * itemConfig[cell.name].m_itemData.m_shared.m_durabilityPerLevel">-->
                                                        <input class="slider is-fullwidth is-medium has-output" type="range" v-model.number="cell.durability" min="1" :max="cell.quality * itemConfig[cell.name].m_itemData.m_shared.m_durabilityPerLevel">
                                                        <output for="sliderWithValue">{{ cell.durability }}</output>
                                                    </div>
                                                </div>
                                                <div class="field">
                                                    <label class="checkbox">
                                                        <input type="checkbox" v-model="cell.equipped"> Equipped
                                                    </label>
                                                </div>
                                                <div class="field" v-if="recipeConfig[cell.name]"><!-- v-if="itemConfig[cell.name].crafter"> -->
                                                    <div class="field"><div class="control"><label class="label">Crafted by</label></div></div>
                                                    <div class="field has-addons">
                                                        <div class="control">
                                                            <input class="input" type="text" v-model="cell.crafterId">
                                                        </div>
                                                        <div class="control">
                                                            <input class="input" type="text" v-model="cell.crafterName">
                                                        </div>
                                                        <div class="control">
                                                            <button class="button is-info" @click="cell.crafterId = playerData.playerId; cell.crafterName = playerData.playerName || 'Stranger';">Me</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!--
                                                <div class="field">
                                                    <label class="label">Move</label>
                                                    <div class="control">
                                                        <button class="button" @click="cell.position.x -= 1; cell.position.y -= 1;">↖</button>
                                                        <button class="button">⬆</button>
                                                        <button class="button">↗</button><br>
                                                        <button class="button">R</button>
                                                        <button class="button">D</button>
                                                    </div>
                                                </div>
                                                -->

                                                <div class="field">
                                                    <ul>
                                                        <li><strong>Armor:</strong> {{ itemConfig[cell.name].m_itemData.m_shared.m_armor + ((cell.quality-1)*2) }}</li>
                                                        <li><strong>Weight:</strong> {{ itemConfig[cell.name].m_itemData.m_shared.m_weight.toFixed(2) }}</li>
                                                    </ul>
                                                </div>

                                                <div class="field is-grouped">
                                                    <p class="control"><button class="button is-success" @click="cell.isEditing = false">OK</button></p>
                                                    <p class="control"><button class="button is-danger" @click="deleteInventoryItem(parseInt(pos_y), pos_x)">Delete</button></p>
                                                    <p class="control"><button class="button is-info" @click="cell.isEditing = false; copyInventoryItem = cell">Clone</button></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="modal-close is-large" aria-label="close" @click="cell.isEditing = false;"></button>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <p v-if="copyInventoryItem">
                        <button class="button is-info" @click="copyInventoryItem = undefined">Cancel paste</button>
                        <br /><br />
                    </p>

                    <p>
                        <ul>
                            <li><strong>Total weight:</strong> {{ inventoryWeight.toFixed(2) }}</li>
                            <li><strong>Total armor:</strong> {{ inventoryArmor }}</li>
                        </ul>
                    </p>
                    <br />
                    <!--
                    <form @submit="inventoryManage">
                        <div class="field is-grouped">
                            <div class="control">
                                <input type="text" class="input" name="slot">
                            </div>
                            <div class="control">
                                <button class="button is-success" type="submit" name="method" value="export">Save</button>
                            </div>
                            <div class="control">
                                <button class="button is-success" type="submit" name="method" value="import">Load</button>
                            </div>
                        </div>
                    </form>
                    -->
                </details>

                <!-- FOOD -->
                <details class="block" v-if="imacheater">
                    <summary class="title">Food</summary>
                    <table class="table is-striped is-fullwidth" v-if="playerData.foods.length">
                        <tr>
                            <th>Name</th>
                            <th>HP</th>
                            <th>Stamina</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr v-for="(food, i) in playerData.foods" :key="food">
                            <td>
                                <div class="select">
                                    <select v-model="food.name">
                                        <option v-for="obj in foodItems" :key="obj.name" :value="obj.name">{{ obj.m_itemData.m_shared.m_name_EN }}</option>
                                    </select>
                                </div>
                            </td>
                            <td><input class="input" type="number" v-model="food.hp" min="0" :max="itemConfig[food.name].m_itemData.m_shared.m_food || 999"></td>
                            <td><input class="input" type="number" v-model="food.stamina" min="0" :max="itemConfig[food.name].m_itemData.m_shared.m_foodStamina || 999"></td>
                            <td><button class="button is-success" @click="refillFood(i)" :disabled="!itemConfig[food.name] || !itemConfig[food.name].m_itemData.m_shared.m_food || !itemConfig[food.name].m_itemData.m_shared.m_foodStamina">Refill</button></td>
                            <td><button class="button is-danger" @click="deleteFood(i)">Delete</button></td>
                        </tr>
                        
                    </table>
                    <p class="has-text-grey pb-5" v-else>None</p>

                    <form @submit="addFood">
                        <div class="field is-grouped">
                            <div class="control">
                                <div class="select">
                                    <select name="name" ref="addFoodName">
                                        <option v-for="obj in foodItems" :key="obj.name" :value="obj.name">{{ obj.m_itemData.m_shared.m_name_EN }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="control">
                                <button class="button is-success" type="submit" :disabled="playerData.foods.length >= 3">Add</button>
                            </div>
                        </div>
                    </form>
                </details>

                <!-- SKILLS -->
                <details class="block" v-if="imacheater">
                    <summary class="title">Skills</summary>
                    <table class="table is-striped is-fullwidth">
                        <tr>
                            <th>ID</th>
                            <th>Level</th>
                            <th>Accumulator</th>
                            <th></th>
                        </tr>
                        <tr v-for="skill in playerData.skills" :key="skill">
                            <td>{{ skillNames[skill.id.toString()] ? skillNames[skill.id.toString()] : skill.id }}</td>
                            <td>
                                <input class="input" type="number" v-model="skill.level">
                                <!--<input class="slider is-fullwidth has-output" type="range" v-model.number="skill.level" min="0" max="99">
                                <output>{{ skill.level }}</output>-->
                            </td>
                            <td>
                                <input class="input" type="number" v-model="skill.accumulator">
                            </td>
                            <td>
                                <button class="button is-success" @click="maxSkill(skill.id)">Max</button>
                            </td>
                        </tr>
                    </table>
                    <div class="control">
                        <button class="button is-success" @click="maxAllSkills()">Max all</button>
                    </div>
                </details>
                
                <!-- WORLDS -->
                <details class="block" v-if="playerData.worldData">
                    <summary class="title">Worlds ({{playerData.worldData.length}})</summary>
                    <div class="card" v-for="(world, i) in playerData.worldData" :key="world.worldDataID">
                        <div class="card-content">
                            <h4 class="title is-4">{{ world.worldDataID}}</h4>
                            <div class="content">
                                <table class="table">
                                    <tr>
                                        <th></th>
                                        <th>X</th>
                                        <th>Y</th>
                                        <th>Z</th>
                                    </tr>
                                    <tr>
                                        <td><strong>Spawn point:</strong></td>
                                        <td><input class="input" type="text" v-model="world.spawnPoint.x" :disabled="!imacheater"></td>
                                        <td><input class="input" type="text" v-model="world.spawnPoint.y" :disabled="!imacheater"></td>
                                        <td><input class="input" type="text" v-model="world.spawnPoint.z" :disabled="!imacheater"></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Death point:</strong></td>
                                        <td><input class="input" type="text" v-model="world.deathPoint.x" :disabled="!world.hasDeathPoint || !imacheater"></td>
                                        <td><input class="input" type="text" v-model="world.deathPoint.y" :disabled="!world.hasDeathPoint || !imacheater"></td>
                                        <td><input class="input" type="text" v-model="world.deathPoint.z" :disabled="!world.hasDeathPoint || !imacheater"></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Logout point:</strong></td>
                                        <td><input class="input" type="text" v-model="world.logoutPoint.x" :disabled="!imacheater"></td>
                                        <td><input class="input" type="text" v-model="world.logoutPoint.y" :disabled="!imacheater"></td>
                                        <td><input class="input" type="text" v-model="world.logoutPoint.z" :disabled="!imacheater"></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Home point:</strong></td>
                                        <td><input class="input" type="text" v-model="world.homePoint.x" :disabled="!imacheater"></td>
                                        <td><input class="input" type="text" v-model="world.homePoint.y" :disabled="!imacheater"></td>
                                        <td><input class="input" type="text" v-model="world.homePoint.z" :disabled="!imacheater"></td>
                                    </tr>
                                </table>

                                <div class="field is-grouped" v-if="imacheater">
                                    <p class="control">
                                        <button :disabled="!world.hasDeathPoint" class="button is-success is-small" @click="world.logoutPoint = world.deathPoint; world.spawnPoint = world.deathPoint;">Teleport to death point</button>
                                    </p>
                                    <p class="control">
                                        <button :disabled="!world.homePoint" class="button is-success is-small" @click="world.logoutPoint = world.homePoint; world.spawnPoint = world.homePoint;">Teleport home</button>
                                    </p>
                                </div>

                                <hr />

                                <div class="worldmap" :id="'worldmap_' + i"></div>
                                    
                                <br />

                                <div class="field">
                                    <div class="control">
                                        <button class="button is-small is-success" @click="placeLeafletMarkers(i)">Refresh pins</button>
                                    </div>
                                </div>

                                <ul>
                                    <li><strong>Blue:</strong> your pins</li>
                                    <li><strong>Green:</strong> home/bed/logout</li>
                                    <li><strong>Red:</strong> death</li>
                                    <li><strong>Yellow:</strong> bosses</li>
                                </ul>

                                <p>
                                    <strong>Explored:</strong> {{ (world.exploredPercent * 100).toFixed(5) }}%
                                </p>

                                <div class="control">
                                    <button class="button is-small" @click="exploreFullMap(i)">Unlock entire map</button>
                                    <p class="help">This will take a few seconds.</p>
                                </div>

                                <br><br><p class="title is-5">Pins</p>
                                <table class="table" v-if="world.pins.length">
                                    <tr>
                                        <th>Name</th>
                                        <th>X</th>
                                        <th>Y</th>
                                        <th>Z</th>
                                        <th>Icon</th>
                                        <th></th>
                                    </tr>
                                    <tr v-for="pin in world.pins" :key="pin">
                                        <td>{{ pin.name }}</td>
                                        <td>{{ pin.position.x }}</td><td>{{ pin.position.y }}</td><td>{{ pin.position.z }}</td>
                                        <td>{{ pinIconNames[pin.type] || pin.type }}</td>
                                        <td v-if="imacheater">
                                            <button class="button is-success is-small" @click="world.logoutPoint = pin.position; world.spawnPoint = pin.position;">Teleport to</button>
                                        </td>
                                    </tr>
                                </table>
                                <span class="has-text-grey" v-else>None</span>
                            </div>

                            <div class="block">
                                Show player on map: {{ world.showPlayerOnMap ? 'Yes' : 'No' }}
                            </div>

                            <div class="control">
                                <button class="button is-small is-danger" @click="deleteWorld(i)">Delete</button>
                            </div>
                        </div>
                    </div>
                </details>

                <!-- EXPORT / JSON -->
                <div class="block export-form">
                    <div class="field is-grouped">
                        <div class="control">
                            <button :class="{ button: true, 'is-success': true, 'is-loading': isExporting }" :disabled="isExporting" @click="doExport">
                                Export
                            </button>
                        </div>
                        <div class="control">
                            <button :class="{ button: true, 'is-loading': isExporting }" :disabled="isExporting" @click="doExportJSON">
                                JSON
                            </button>
                        </div>
                    </div>
                    <p class="subtitle is-6">Exporting might take a few seconds, and the page might lock up.</p>
                </div>
            </div>
            
            <!-- CREDITS -->
            <div class="block">
                <hr />
                <p class="subtitle is-6 has-text-grey">
                    Thanks to #programming on the Valheim discord.<br>
                    Special thanks to Balu for the C# source and item dumps.
                </p>
            </div>

        </div>

    </section>

</template>

<script lang="ts">
// vue
import { defineComponent, nextTick } from 'vue';
import Multiselect from '@vueform/multiselect';

// ts
// import JSONBig { useNativeBigInt: true } from 'json-bigint';
import JSONBig from 'json-bigint';
const JSONBigStrict = JSONBig({ useNativeBigInt: true });
// const JSONBig = require('json-bigint')({ useNativeBigInt: true });

import BinaryReader from './libs/BinaryReader';
import RLE from './libs/RLE';
import { sha512 } from 'js-sha512'; /** @todo: implement */
import { crc32 } from 'crc';

import leaflet from 'leaflet';

// import pogo from './data/pogo';

// const { subtle } = require('crypto').webcrypto;

// game data
import gameObjects from './data/itemdrops.json';
import gameRecipes from './data/recipes.json';
import { InventoryItem, PlayerData, WorldDataEntry, Vector3, MItem, SubmitEvent, InventoryItemEmpty, MRecipe } from '@/valheim';

const skillNames = {
    "2": "Knives",
    "3": "Clubs",
    "6": "Blocking",
    "7": "Axes",
    "8": "Bows",
    "11": "Unarmed",
    "12": "Pickaxes",
    "13": "Woodcutting",

    "100": "Jump",
    "101": "Sneaking",
    "102": "Running",
    "103": "Swim",
};

const biomeNames = {
    "0": "None",
    "1": "Meadows",
    "2": "Swamp",
    "4": "Mountain",
    "8": "BlackForest",
    "16": "Plains",
    "32": "AshLands",
    "64": "DeepNorth",
    "256": "Ocean",
    "512": "Mistlands",
    "513": "BiomesMax",
}

const pinIconNames = {
    "0": "Fire",
    "1": "Icon1",
    "2": "Icon2",
    "3": "Dot",
    "4": "Death",
    "5": "Bed",
    "6": "Icon4",
    "7": "Shout",
    "8": "None",
    "9": "Boss",
    "10": "Player",
    "11": "RandomEvent",
    "12": "Ping",
    "13": "EventArea"
};

function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : { r: 0, g: 0, b: 0 };
}

/**
 * Thanks to balu92 for the original c# code and item dumps
 */

export default defineComponent({
    name: 'App',
    $refs: {
        addFoodHp: {} as HTMLInputElement,
        addFoodStamina: {} as HTMLInputElement,
    },
    data: () => {
        return {
            APP_VERSION: process.env.VUE_APP_VERSION,
            supportedVersion: {
                player: 33,
                miniMap: 4,
                playerData: 24,
                inventory: 103,
                skills: 2,
            },
            buffer: {} as ArrayBuffer,
            dataview: {} as DataView,
            pointer: 0,
            playerData: {} as PlayerData,
            gameObjects: gameObjects as MItem[],
            gameRecipes: gameRecipes as MRecipe[],
            unsupportedVersion: false,
            skillNames: skillNames as Record<string, string>,
            pinIconNames: pinIconNames as Record<string, string>,
            biomeNames: biomeNames as Record<string, string>,
            itemConfig: {} as Record<string, MItem>,
            recipeConfig: {} as Record<string, MRecipe>,
            isExporting: false,
            worldCanvasSize: 2048,
            inventoryBackups: {} as Record<string, InventoryItem[]>,
            imacheater: false,
            copyInventoryItem: undefined as InventoryItem | undefined,
            playerBeards: [
                "BeardNone",
                "Beard1",
                "Beard2",
                "Beard3",
                "Beard4",
                "Beard5",
                "Beard6",
                "Beard7",
                "Beard8",
                "Beard9",
                "Beard10", 
            ],
            playerHairs: [
                "HairNone",
                "Hair1",
                "Hair2",
                "Hair3",
                "Hair4",
                "Hair5",
                "Hair6",
                "Hair7",
                "Hair8",
                "Hair9",
                "Hair10",
                "Hair11",
                "Hair12",
                "Hair13",
                "Hair14",
            ]
        };
    },
    components: {
        Multiselect
    },
    mounted() {
        for(const item of this.gameObjects){
            this.itemConfig[item.name] = item;
        }
        for(const recipe of this.gameRecipes){
            this.recipeConfig[recipe.name.substring(7)] = recipe;
        }
        if(localStorage.inventoryBackups){
            this.inventoryBackups = JSONBig.parse(localStorage.inventoryBackups || {});
        }
    },
    watch: {
        inventoryBackups: {
            deep: true,
            handler(newData){
                localStorage.inventoryBackups = JSONBig.stringify(newData);
                console.log("Inventory backups saved");
            }
        }
    },
    methods: {
        handleFile(event: InputEvent){
            const input = event.target as HTMLInputElement;
            if(!input || !input.files) return;
            console.log("handleFile");
            const file = input.files[0];
            console.log("file", file);
            const fileURL = URL.createObjectURL(file);
            console.log("fileURL", fileURL);
            
            const reader = new FileReader();
            reader.onload = (evt) => {

                const buffer = evt.target?.result as ArrayBuffer;

                if(!buffer){
                    console.error("no buffer");
                    return;
                }

                this.doImport(buffer);

            }

            reader.readAsArrayBuffer(file);

            console.log(this.playerData);            

        },
        doImport(buffer: ArrayBuffer) {

            this.unsupportedVersion = false;

            const binReader = new BinaryReader(buffer);
                
            this.playerData.saveDataLength = binReader.ReadInt32();

            this.playerData.dataVersions = {};

            this.playerData.dataVersions.player = binReader.ReadInt32("player data version");
            this.playerData.playerKills = binReader.ReadInt32("player kills");
            this.playerData.playerDeaths = binReader.ReadInt32("player deaths");
            this.playerData.playerCrafts = binReader.ReadInt32("player crafts");
            this.playerData.playerBuilds = binReader.ReadInt32("player builds");

            if(this.playerData.dataVersions.player !== this.supportedVersion.player){
                this.unsupportedVersion = true;
                // console.warn("unsupported version", "player", this.playerData.dataVersions.player);
            }

            const worldDataCount = binReader.ReadInt32("world data count") || 0;
            // console.log(`Number of world data: ${worldDataCount}`);

            this.playerData.worldData = [];
            for (let i = 0; i < worldDataCount; i++) {
                
                const worldDataEntry: WorldDataEntry = {
                    worldDataID: binReader.ReadUInt64("world data id"),
                    hasCustomSpawnPoint: binReader.ReadBoolean("has custom spawn point"),
                    spawnPoint: binReader.ReadVector3("world spawn point"),
                    hasLogoutPoint: binReader.ReadBoolean("has world logout point"),
                    logoutPoint: binReader.ReadVector3("world logout point"),
                    hasDeathPoint: binReader.ReadBoolean("world has death point"),
                    deathPoint: binReader.ReadVector3("world death point"),
                    homePoint: binReader.ReadVector3("world home point"),
                    hasMapData: binReader.ReadBoolean("world has map data"),
                    // exploredGrid: [],
                    exploredString: "",
                    pins: [],
                    textureSize: -1,
                    miniMapMapversion: -1,
                    exploredPercent: -1
                };

                // console.log(`HasMapData: ${worldDataEntry.hasMapData}`);
                if (worldDataEntry.hasMapData) {
                    
                    const mapDataLength = binReader.ReadInt32("mapData length");
                    const mapData = binReader.ReadBytes(mapDataLength);

                    // console.log("mapDataLength", mapDataLength);

                    if (mapData) {
                        
                        //using var worldMemory = new MemoryStream(mapData);
                        const worldReader = new BinaryReader(mapData);

                        worldDataEntry.miniMapMapversion = worldReader.ReadInt32("minimap version");

                        if(worldDataEntry.miniMapMapversion !== this.supportedVersion.miniMap){
                            this.unsupportedVersion = true;
                            // console.warn("unsupported version", "miniMap");
                        }

                        worldDataEntry.textureSize = worldReader.ReadInt32("minimap texture size");
                        // console.log(`TextureSize: ${worldDataEntry.textureSize}`);

                        // worldDataEntry.exploredGrid = [];
                        
                        let explored = 0;
                        let tmpExplored = "";
                        for (let x = 0; x < worldDataEntry.textureSize; x++) {
                            // const row = [];
                            for (let y = 0; y < worldDataEntry.textureSize; y++) {
                                // pixel at X:Y is explored by the player on this map
                                const isExplored = worldReader.ReadBoolean();
                                if (isExplored) explored++;
                                // row.push(isExplored);
                                tmpExplored += isExplored ? 'X' : 'O';
                            }
                            // worldDataEntry.exploredGrid.push(row);
                        }
                        // console.log(tmpExplored);
                        worldDataEntry.exploredString = new RLE().encode(tmpExplored);
                        // worldDataEntry.exploredString = LZString.compress(tmpExplored);
                        // console.log(worldDataEntry.exploredString);

                        worldDataEntry.exploredPercent = explored / (worldDataEntry.textureSize * worldDataEntry.textureSize);

                        // not really, since you can't explore the corners
                        // console.log(`${worldDataEntry.exploredPercent*100}% explored!`);

                        worldDataEntry.pins = [];
                        
                        const numberOfPins = worldReader.ReadInt32("number of pins");
                        // console.log(`Number of player pins: ${numberOfPins}`);
                        for (let j = 0; j < numberOfPins; j++) {
                            const pin = {
                                name: worldReader.ReadString(`pin ${j} name`),
                                position: worldReader.ReadVector3(),
                                // position: { x: worldReader.ReadSingle(), y: worldReader.ReadSingle(), z: worldReader.ReadSingle() },
                                type: worldReader.ReadInt32(`pin ${j} type`),
                                checked: worldReader.ReadBoolean(`pin ${j} checked`) // has X when the player right-clicks it
                            };
                            worldDataEntry.pins.push(pin);
                        }

                        if( worldDataEntry.miniMapMapversion >= 4){
                            worldDataEntry.showPlayerOnMap = worldReader.ReadBoolean();
                        }
                    }
                    
                }

                this.playerData.worldData.push(worldDataEntry);

            }

            /** @todo: read 7-bit integer or something */
            this.playerData.playerName = binReader.ReadString("player name", true);
            // this.playerData.playerName = unescape(encodeURIComponent(this.playerData.playerName));

            // console.log(`Player's name ${this.playerData.playerName}`);

            this.playerData.playerId = binReader.ReadUInt64("player id");
            // console.log(`Player's generated ID ${this.playerData.playerId}`);
            
            this.playerData.startSeed = binReader.ReadString("start seed");
            // console.log(`Start seed ${this.playerData.startSeed}`);

            this.playerData.hasPlayerData = binReader.ReadBoolean();
            // console.log(`Has Player Data: ${this.playerData.hasPlayerData}`);

            if (this.playerData.hasPlayerData) {

                const playerDataLength = binReader.ReadInt32();
                const playerData = binReader.ReadBytes(playerDataLength);

                if (playerData) {

                    // using var playerMemory = new MemoryStream(playerData);
                    const playerReader = new BinaryReader(playerData);

                    this.playerData.dataVersions.playerData = playerReader.ReadInt32();
                    // console.log(`\tPlayer DataVersion: ${this.playerData.playerDataVersion}`);
                    if(this.playerData.dataVersions.playerData !== this.supportedVersion.playerData){
                        this.unsupportedVersion = true;
                        // console.warn("unsupported version", "playerData");
                    }

                    this.playerData.playerMaxHealth = playerReader.ReadSingle();
                    // console.log(`\tMax Health: ${this.playerData.playerMaxHealth}`);
                    this.playerData.playerHealth = playerReader.ReadSingle();
                    // console.log(`\tHealth: ${this.playerData.playerHealth}`);
                    this.playerData.playerStamina = playerReader.ReadSingle();
                    // console.log(`\tStamina: ${this.playerData.playerStamina}`);

                    this.playerData.firstSpawn = playerReader.ReadBoolean();
                    // console.log(`\tFirst spawn: ${this.playerData.firstSpawn}`);

                    this.playerData.timeSinceDeath = playerReader.ReadSingle()
                    // console.log(`\tTime since death: ${this.playerData.timeSinceDeath}`);

                    this.playerData.guardianPower = playerReader.ReadString();
                    // console.log(`\tGuardian power: ${this.playerData.guardianPower}`);

                    this.playerData.guardianPowerCooldown = playerReader.ReadSingle()
                    // console.log(`\tGuardian power CD: ${this.playerData.guardianPowerCooldown}`);

                    this.playerData.dataVersions.inventory = playerReader.ReadInt32();
                    // console.log(`\tInventory DataVersion: ${this.playerData.inventoryDataVersion}`);
                    if(this.playerData.dataVersions.inventory !== this.supportedVersion.inventory){
                        this.unsupportedVersion = true;
                        // console.warn("unsupported version", "inventory");
                    }

                    // inventory
                    const inventoryItemCount = playerReader.ReadInt32();
                    // console.log(`\tNumber of items in inventory: ${inventoryItemCount}`);
                    this.playerData.inventory = [];
                    for (let i = 0; i < inventoryItemCount; i++) {
                        /*
                        console.log(`\tName: ${playerReader.ReadString()} (x${playerReader.ReadInt32()})\n`);
                        console.log(`\t\tDurability: ${playerReader.ReadSingle()}\n`);
                        console.log(`\t\tPosition: Column:${playerReader.ReadInt32()}, Row:${playerReader.ReadInt32()}\n`);
                        console.log(`\t\tIsEquiped: ${playerReader.ReadBoolean()}\n`);
                        console.log(`\t\tQuality: ${playerReader.ReadInt32()}\n`);
                        console.log(`\t\tVariant: ${playerReader.ReadInt32()}\n`);
                        console.log(`\t\tCrafter: ${playerReader.ReadInt64()} (${playerReader.ReadString()})\n`);
                        */

                        const inventoryItem: InventoryItem = {
                            name: playerReader.ReadString(),
                            amount: playerReader.ReadInt32(),
                            durability: playerReader.ReadSingle(),
                            position: { x: playerReader.ReadInt32(), y: playerReader.ReadInt32() },
                            equipped: playerReader.ReadBoolean(),
                            quality: playerReader.ReadInt32(),
                            variant: playerReader.ReadInt32(),
                            crafterId: playerReader.ReadUInt64(),
                            crafterName: playerReader.ReadString("inventory item crafter name", true),
                        };

                        this.playerData.inventory.push(inventoryItem);
                    }

                    // known
                    const knownRecipesCount = playerReader.ReadInt32("known recipes count");
                    // console.log(`\tKnown recipes: ${knownRecipesCount}`);
                    this.playerData.knownRecipes = [];
                    for (let i = 0; i < knownRecipesCount; i++) {
                        this.playerData.knownRecipes.push(playerReader.ReadString());
                        // console.log(`\t\t ${playerReader.ReadString()}`);
                    }

                    const knownStationsCount = playerReader.ReadInt32("known stations count");
                    // console.log(`\tKnown stations: ${knownStationsCount}`);
                    this.playerData.knownStations = {};
                    for (let i = 0; i < knownStationsCount; i++) {
                        this.playerData.knownStations[ playerReader.ReadString() ] = playerReader.ReadInt32();
                        // console.log(`\t\t${i}: ${playerReader.ReadString()} (LVL: ${playerReader.ReadInt32()})`);
                    }

                    const knownMaterialCount = playerReader.ReadInt32("known material count");
                    // console.log(`\tKnown materials: ${knownMaterialCount}`);
                    this.playerData.knownMaterials = [];
                    for (let i = 0; i < knownMaterialCount; i++) {
                        this.playerData.knownMaterials.push(playerReader.ReadString());
                        // console.log(`\t\t${playerReader.ReadString()}`);
                    }

                    const knownTutorialCount = playerReader.ReadInt32("known tutorial count");
                    // console.log(`\tKnown tutorials: ${knownTutorialCount}`);
                    this.playerData.knownTutorials = [];
                    for (let i = 0; i < knownTutorialCount; i++) {
                        this.playerData.knownTutorials.push(playerReader.ReadString());
                        // console.log(`\t\t${playerReader.ReadString()}`);
                    }

                    const uniquesCount = playerReader.ReadInt32("uniques count");
                    // console.log(`\tUniques: ${uniquesCount}`);
                    this.playerData.knownUniques = [];
                    for (let i = 0; i < uniquesCount; i++) {
                        this.playerData.knownUniques.push(playerReader.ReadString());
                        // console.log(`\t\t${playerReader.ReadString()}`);
                    }

                    const triophiesCount = playerReader.ReadInt32("trophies count");
                    // console.log(`\tTrophies: ${triophiesCount}`);
                    this.playerData.knownTrophies = [];
                    for (let i = 0; i < triophiesCount; i++) {
                        this.playerData.knownTrophies.push(playerReader.ReadString());
                        // console.log(`\t\t${playerReader.ReadString()}`);
                    }

                    const knownBiomes = playerReader.ReadInt32("known biomes amount");
                    // console.log(`\tKnown biomes: ${knownBiomes}`);
                    this.playerData.knownBiomes = [];
                    for (let i = 0; i < knownBiomes; i++) {
                        const biome = playerReader.ReadInt32();
                        this.playerData.knownBiomes.push(biome);
                        // console.log(`\t\t[${biome}] + ${biome}`);
                    }

                    const knownTexts = playerReader.ReadInt32("known texts amount");
                    // console.log(`\tKnown Texts: ${knownTexts}`);
                    this.playerData.knownTexts = {};
                    for (let i = 0; i < knownTexts; i++) {
                        const k = playerReader.ReadString();
                        const v = playerReader.ReadString();
                        this.playerData.knownTexts[k] = v;
                        // console.log(`\t\t${playerReader.ReadString()}: ${playerReader.ReadString()}`);
                    }

                    // looks
                    this.playerData.beard = playerReader.ReadString("beard");
                    // console.log(`\tBeard: ${this.playerData.beard}`);
                    this.playerData.hair = playerReader.ReadString("hair");
                    // console.log(`\tHair: ${this.playerData.hair}`);
                    
                    this.playerData.skinColour = playerReader.ReadColour("skin colour");
                    this.playerData.hairColour = playerReader.ReadColour("hair colour");

                    // console.log(`\tSkin colour: rgb(${Math.round(playerReader.ReadSingle() * 255)},${Math.round(playerReader.ReadSingle() * 255)},${Math.round(playerReader.ReadSingle() * 255)})`);
                    // console.log(`\tHair colour: rgb(${Math.round(playerReader.ReadSingle() * 255)},${Math.round(playerReader.ReadSingle() * 255)},${Math.round(playerReader.ReadSingle() * 255)})`);
                    this.playerData.modelIndex = playerReader.ReadInt32("model index");
                    // console.log(`\tModel index: ${this.playerData.modelIndex}`);

                    if(this.playerData.beard.length > 20){
                        alert("This character file uses an unsupported third party modification.");
                        this.playerData = {} as PlayerData;
                        return;
                    }

                    // Food
                    const foodCount = playerReader.ReadInt32("foodCount");

                    if(foodCount > 3){
                        throw `More than 3 foods read (${foodCount})`;
                    }
                    // console.log(`\tFoods: ${foodCount}`);
                    this.playerData.foods = [];
                    for (let i = 0; i < foodCount; i++) {
                        const food = {
                            name: playerReader.ReadString("food name"),
                            hp: playerReader.ReadSingle("food hp"),
                            stamina: playerReader.ReadSingle("food stamina")
                        };
                        this.playerData.foods.push(food);
                        // console.log(`\t\t${playerReader.ReadString()}: \r\n\t\t\tHP: ${playerReader.ReadSingle()}\r\n\t\t\tStamina: ${playerReader.ReadSingle()}`);
                    }

                    // Skills
                    this.playerData.dataVersions.skills = playerReader.ReadInt32("skills dataVersion");
                    // console.log(`Skills DataVersion: ${this.playerData.skillsDataVersion}`);
                    if(this.playerData.dataVersions.skills !== this.supportedVersion.skills){
                        this.unsupportedVersion = true;
                        // console.warn("unsupported version", "skills");
                    }

                    const skillCount = playerReader.ReadInt32();
                    // console.log(`\tSkills: ${skillCount}`);
                    this.playerData.skills = [];
                    for (let i = 0; i < skillCount; i++) {
                        const skill = {
                            id: playerReader.ReadInt32(),
                            level: playerReader.ReadSingle(),
                            accumulator: playerReader.ReadSingle()
                        };
                        this.playerData.skills.push(skill);
                        // console.log(`\t\t${playerReader.ReadInt32()}: \r\n\t\t\tLevel: ${playerReader.ReadSingle()}\r\n\t\t\tAccumulator: ${playerReader.ReadSingle()}`);
                    }

                }

            }

            // console.debug(`Hash start: ${binReader.tell()}`);
            this.playerData.hashStart = binReader.tell();
            this.playerData.hashLength = binReader.ReadInt32("get hash length");

            // const hash = BitConverter.ToString(binReader.ReadBytes(hashLength)).Replace("-", "");
            // const dec = new TextDecoder("iso-8859-2");
            const hashRaw = binReader.ReadBytes(this.playerData.hashLength);
            // const hashRaw = binReader.ReadByteArray();
            if(hashRaw){
                this.playerData.hashRaw = hashRaw;
                
                // const hashArray = Array.from(new Uint8Array(this.playerData.hashRaw));                     // convert buffer to byte array
                // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                // console.log("hash", this.playerData.hashRaw, hashHex);

                // this.playerData.hash = dec.decode(this.playerData.hashRaw);
                // console.debug(`HASH: ${this.playerData.hash}`);
            }

            this.doBackup(this.playerData);
            
            nextTick(() => {
                if(this.playerData.worldData) for(let i = 0; i < this.playerData.worldData.length; i++ ) this.generateMap(i);
            });

            // console.debug(this.playerData);

        },
        doExport(){
            
            let finalBuffer;
            
            try {
                finalBuffer = this.doExportBinary();
            } catch (error) {
                alert(`Export error: ${error}`);
                this.isExporting = false;
                return;
            }

            if(!finalBuffer){
                console.error("no buffer");
                return;
            }
            const blob = new Blob([finalBuffer], { type: "application/octet-stream"});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.playerData.playerName?.toLowerCase()}.fch`;
            link.click();
        },
        doExportBinary() {

            if(!this.playerData) return;

            this.isExporting = true;

            // const start = new Date().getTime();

            // console.debug(new Date().getTime() - start, "Start export");
            const buffer = new ArrayBuffer( (this.playerData.saveDataLength || 8_000_000 ) + (1_000_000) ); // can't resize buffers?
            const binWriter = new BinaryReader(buffer);
            
            // console.debug(new Date().getTime() - start, "Start data");
            binWriter.skip(4);

            const dataStartOffset = binWriter.tell();

            binWriter.WriteInt32(this.playerData.dataVersions.player, "playerVersion");
            binWriter.WriteInt32(this.playerData.playerKills, "playerKills");
            binWriter.WriteInt32(this.playerData.playerDeaths, "playerDeaths");
            binWriter.WriteInt32(this.playerData.playerCrafts, "playerCrafts");
            binWriter.WriteInt32(this.playerData.playerBuilds, "playerBuilds");

            binWriter.WriteInt32(this.playerData.worldData?.length || 0, "worldData length"); // count

            // console.debug(new Date().getTime() - start, "Start export world data");
            if (this.playerData.worldData){
                // const localWorldData = this.playerData.worldData.slice(); // perf
                // const localWorldData = JSONBig.parse(JSONBig.stringify(this.playerData.worldData));
                // console.log(localWorldData);
                for (let i = 0; i < this.playerData.worldData.length; i++) {

                    // make local copy to not make vue take control
                    const worldDataEntry = Object.assign({}, this.playerData.worldData[i]);
                    // const worldDataEntry = JSON.parse(JSON.stringify(this.playerData.worldData[i]));

                    binWriter.WriteUInt64(worldDataEntry.worldDataID, "world data id");
                    binWriter.WriteBoolean(worldDataEntry.hasCustomSpawnPoint, "hasCustomSpawnPoint");
                    binWriter.WriteVector3(worldDataEntry.spawnPoint, "spawnPoint");
                    binWriter.WriteBoolean(worldDataEntry.hasLogoutPoint, "hasLogoutPoint");
                    binWriter.WriteVector3(worldDataEntry.logoutPoint, "logoutPoint");
                    binWriter.WriteBoolean(worldDataEntry.hasDeathPoint, "hasDeathPoint");
                    binWriter.WriteVector3(worldDataEntry.deathPoint, "deathPoint");
                    binWriter.WriteVector3(worldDataEntry.homePoint, "homePoint");

                    binWriter.WriteBoolean(worldDataEntry.hasMapData, "hasMapData");

                    if (worldDataEntry.hasMapData) {

                        const mapDataLengthOffset = binWriter.tell();
                        binWriter.skip(4, "mapDataLengthOffset placeholder");
                        
                        binWriter.WriteInt32(worldDataEntry.miniMapMapversion, "minimap version");

                        // write explored map
                        binWriter.WriteInt32(worldDataEntry.textureSize, "texture size");
                        const exploredString = new RLE().decode(worldDataEntry.exploredString.toString());
                        for (let x = 0; x < worldDataEntry.textureSize; x++) {
                            for (let y = 0; y < worldDataEntry.textureSize; y++) {
                                binWriter.WriteBoolean(exploredString.substr( (x * worldDataEntry.textureSize) + y, 1 ) === 'X');
                            }
                        }

                        // write map pins
                        binWriter.WriteInt32(worldDataEntry.pins.length, "pins count");
                        if (worldDataEntry.pins.length > 0){
                            for (let j = 0; j < worldDataEntry.pins.length; j++) {
                                const pin = worldDataEntry.pins[j];
                                if(!pin){
                                    throw new Error(`Invalid pin ${j}`);
                                }
                                binWriter.WriteString(pin.name, "pin name");
                                binWriter.WriteVector3(pin.position, "pin position");
                                binWriter.WriteInt32(pin.type, "pin type");
                                binWriter.WriteBoolean(pin.checked, "pin checked"); // has X when the player right-clicks it
                            }
                        }

                        if( worldDataEntry.miniMapMapversion >= 4){
                            binWriter.WriteBoolean(worldDataEntry.showPlayerOnMap || false);
                        }

                        const tmpOffset = binWriter.tell();
                        binWriter.seek(mapDataLengthOffset);
                        binWriter.WriteInt32(Math.abs(mapDataLengthOffset-tmpOffset)-4, "mapDataLengthOffset");
                        binWriter.seek(tmpOffset);
                    }

                }
            }

            binWriter.WriteString(this.playerData.playerName || "Stranger", "Player's name", true);
            binWriter.WriteUInt64(this.playerData.playerId, "Player's generated ID");
            binWriter.WriteString(this.playerData.startSeed, "Start seed");
            binWriter.WriteBoolean(this.playerData.hasPlayerData, "Has Player Data");

            let playerDataOffsetStart = 0;
            // let playerDataOffsetEnd = 0;

            // console.debug(new Date().getTime() - start, "Start export player data");
            if (this.playerData.hasPlayerData) {

                // const playerDataLength = binReader.ReadInt32();
                // const playerData = binReader.ReadBytes(playerDataLength);
                playerDataOffsetStart = binWriter.tell();
                binWriter.skip(4, "playerDataOffsetStart");

                //if (playerData) {

                    // using var playerMemory = new MemoryStream(playerData);
                    //const playerReader = new BinaryReader(playerData);

                    binWriter.WriteInt32(this.playerData.dataVersions.playerData, "player data version");
                    //console.log(`\tPlayer DataVersion: ${this.playerData.playerDataVersion}`);

                    binWriter.WriteSingle(this.playerData.playerMaxHealth, "player max health");
                    //console.log(`\tMax Health: ${this.playerData.playerMaxHealth}`);

                    binWriter.WriteSingle(this.playerData.playerHealth, "player health");
                    //console.log(`\tHealth: ${this.playerData.playerHealth}`);

                    binWriter.WriteSingle(this.playerData.playerStamina, "player stamina");
                    //console.log(`\tStamina: ${this.playerData.playerStamina}`);

                    binWriter.WriteBoolean(this.playerData.firstSpawn, "player first spawn");
                    //console.log(`\tFirst spawn: ${this.playerData.firstSpawn}`);

                    binWriter.WriteSingle(this.playerData.timeSinceDeath, "player time since death")
                    //console.log(`\tTime since death: ${this.playerData.timeSinceDeath}`);

                    binWriter.WriteString(this.playerData.guardianPower, "player guardian power");
                    //console.log(`\tGuardian power: ${this.playerData.guardianPower}`);

                    binWriter.WriteSingle(this.playerData.guardianPowerCooldown, "player guardian power cooldown")
                    //console.log(`\tGuardian power CD: ${this.playerData.guardianPowerCooldown}`);

                    binWriter.WriteInt32(this.playerData.dataVersions.inventory, "inventory data version");
                    //console.log(`\tInventory DataVersion: ${this.playerData.inventoryDataVersion}`);

                    // inventory
                    binWriter.WriteInt32(this.playerData.inventory?.length || 0, "inventory item count");
                    //console.log(`\tNumber of items in inventory: ${inventoryItemCount}`);
                    if(this.playerData.inventory){
                        if(this.playerData.inventory.length > 100){
                            throw new Error("Inventory too big");
                        }
                        for (let i = 0; i < this.playerData.inventory.length; i++) {
                            const inventoryItem = this.playerData.inventory[i];
                            if(!inventoryItem){
                                throw new Error(`Invalid item: ${i}`);
                            }
                            binWriter.WriteString(inventoryItem.name, "inventory item name");
                            binWriter.WriteInt32(inventoryItem.amount, "inventory item amount");
                            binWriter.WriteSingle(inventoryItem.durability, "inventory item durability");
                            binWriter.WriteInt32(inventoryItem.position.x, "inventory item position x");
                            binWriter.WriteInt32(inventoryItem.position.y, "inventory item position y");
                            binWriter.WriteBoolean(inventoryItem.equipped, "inventory item equipped");
                            binWriter.WriteInt32(inventoryItem.quality, "inventory item quality");
                            binWriter.WriteInt32(inventoryItem.variant, "inventory item variant");

                            if (inventoryItem.crafterId && inventoryItem.crafterName ){
                                binWriter.WriteUInt64(BigInt(inventoryItem.crafterId), "inventory item crafter number");
                                binWriter.WriteString(inventoryItem.crafterName, "inventory item crafter name", true);
                            }else{
                                binWriter.WriteUInt64(0n, "inventory empty item crafter number");
                                binWriter.WriteString("", "inventory empty item crafter name", true);
                            }

                        }
                    }

                    // known
                    binWriter.WriteInt32(this.playerData.knownRecipes.length, "knownRecipesCount");
                    for (let i = 0; i < this.playerData.knownRecipes.length; i++) {
                        binWriter.WriteString(this.playerData.knownRecipes[i]);
                    }

                    binWriter.WriteInt32(Object.keys(this.playerData.knownStations).length, "knownStationsCount");
                    for (const k in this.playerData.knownStations) {
                        binWriter.WriteString(k);
                        binWriter.WriteInt32(this.playerData.knownStations[k]);
                    }

                    binWriter.WriteInt32(this.playerData.knownMaterials.length, "knownMaterialsCount");
                    for (let i = 0; i < this.playerData.knownMaterials.length; i++) {
                        binWriter.WriteString(this.playerData.knownMaterials[i]);
                    }

                    binWriter.WriteInt32(this.playerData.knownTutorials.length, "knownTutorialsCount");
                    for (let i = 0; i < this.playerData.knownTutorials.length; i++) {
                        binWriter.WriteString(this.playerData.knownTutorials[i]);
                    }

                    binWriter.WriteInt32(this.playerData.knownUniques.length, "knownUniquesCount");
                    for (let i = 0; i < this.playerData.knownUniques.length; i++) {
                        binWriter.WriteString(this.playerData.knownUniques[i]);
                    }

                    binWriter.WriteInt32(this.playerData.knownTrophies.length, "knownTrophiesCount");
                    for (let i = 0; i < this.playerData.knownTrophies.length; i++) {
                        binWriter.WriteString(this.playerData.knownTrophies[i]);
                    }

                    binWriter.WriteInt32(this.playerData.knownBiomes.length, "knownBiomesCount");
                    for (let i = 0; i < this.playerData.knownBiomes.length; i++) {
                        binWriter.WriteInt32(this.playerData.knownBiomes[i]);
                    }

                    /*
                    const knownTexts = binWriter.WriteInt32();
                    console.log(`\tKnown Texts: ${knownTexts}`);
                    this.playerData.knownTexts = {};
                    for (let i = 0; i < knownTexts; i++) {
                        const k = binWriter.WriteString();
                        const v = binWriter.WriteString();
                        this.playerData.knownTexts[k] = v;
                        // console.log(`\t\t${binWriter.WriteString()}: ${binWriter.WriteString()}`);
                    }*/
                    binWriter.WriteInt32(Object.keys(this.playerData.knownTexts).length, "knownTextsCount");
                    for (const k in this.playerData.knownTexts) {
                        binWriter.WriteString(k);
                        binWriter.WriteString(this.playerData.knownTexts[k]);
                    }

                    // looks
                    binWriter.WriteString(this.playerData.beard, "beard");
                    //console.log(`\tBeard: ${this.playerData.beard}`);
                    binWriter.WriteString(this.playerData.hair, "hair");
                    //console.log(`\tHair: ${this.playerData.hair}`);
                    
                    binWriter.WriteColour(this.playerData.skinColour, "skin colour");
                    binWriter.WriteColour(this.playerData.hairColour, "hair colour");

                    // console.log(`\tSkin colour: rgb(${Math.round(binWriter.WriteSingle() * 255)},${Math.round(binWriter.WriteSingle() * 255)},${Math.round(binWriter.WriteSingle() * 255)})`);
                    // console.log(`\tHair colour: rgb(${Math.round(binWriter.WriteSingle() * 255)},${Math.round(binWriter.WriteSingle() * 255)},${Math.round(binWriter.WriteSingle() * 255)})`);
                    binWriter.WriteInt32(this.playerData.modelIndex, "modelIndex");
                    //console.log(`\tModel index: ${this.playerData.modelIndex}`);

                    // Food
                    binWriter.WriteInt32(this.playerData.foods.length, "foodCount");
                    for (const food of this.playerData.foods) {
                        binWriter.WriteString(food.name, "food name");
                        binWriter.WriteSingle(food.hp, "food hp");
                        binWriter.WriteSingle(food.stamina, "food stamina");
                        // console.log(`\t\t${binWriter.WriteString()}: \r\n\t\t\tHP: ${binWriter.WriteSingle()}\r\n\t\t\tStamina: ${binWriter.WriteSingle()}`);
                    }

                    // Skills
                    /*
                    console.log(`Skills DataVersion: ${binWriter.WriteInt32()}`);
                    const skillCount = binWriter.WriteInt32();
                    console.log(`\tSkills: ${skillCount}`);
                    this.playerData.skills = [];
                    for (let i = 0; i < skillCount; i++) {
                        const skill = {
                            id: binWriter.WriteInt32(),
                            level: binWriter.WriteSingle(),
                            accumulator: binWriter.WriteSingle()
                        };
                        this.playerData.skills.push(skill);
                        // console.log(`\t\t${playerReader.ReadInt32()}: \r\n\t\t\tLevel: ${playerReader.ReadSingle()}\r\n\t\t\tAccumulator: ${playerReader.ReadSingle()}`);
                    }*/
                    binWriter.WriteInt32(this.playerData.dataVersions.skills, "skillsDataVersion");
                    binWriter.WriteInt32(this.playerData.skills.length, "skillCount");
                    for (const skill of this.playerData.skills) {
                        binWriter.WriteInt32(skill.id, "skill id");
                        binWriter.WriteSingle(skill.level, "skill level");
                        binWriter.WriteSingle(skill.accumulator, "skill accumulator");
                        // console.log(`\t\t${binWriter.WriteString()}: \r\n\t\t\tHP: ${binWriter.WriteSingle()}\r\n\t\t\tStamina: ${binWriter.WriteSingle()}`);
                    }

                const tmpOffset = binWriter.tell();
                binWriter.seek(playerDataOffsetStart, "seek back to playerDataOffset");
                binWriter.WriteInt32(Math.abs(playerDataOffsetStart-tmpOffset)-4, "write playerDataOffset");
                binWriter.seek(tmpOffset, "go back to previous offset from playerDataOffset");

                // playerDataOffsetEnd = binWriter.tell();

                //}

            }

            const lastSaveBuffer = binWriter.tell();
            binWriter.seek(0);
            binWriter.WriteInt32(lastSaveBuffer-4, "last buffer total size");
            // console.log(`Last size: ${lastSaveBuffer} / ${binWriter.buffer.byteLength}`);
            binWriter.seek(lastSaveBuffer, "seek back to end");

            const hash = sha512.arrayBuffer(binWriter.buffer.slice(dataStartOffset, binWriter.tell()));

            binWriter.WriteInt32(this.playerData.hashLength);
            binWriter.WriteBytes(hash);

            const lastBuffer = binWriter.tell();

            const finalBuffer = binWriter.buffer.slice(0, lastBuffer);

            this.isExporting = false;

            return finalBuffer;

        },
        doExportJSON() {
            this.isExporting = true;

            if(this.playerData.worldData) for(const world of this.playerData.worldData){ world.leafletMap.remove(); world.leafletMap = undefined; } // remove map
            
            const blob = new Blob([JSONBig.stringify(this.playerData)], { type: "application/json"});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.playerData.playerName?.toLowerCase()}.json`;
            link.click();
            this.isExporting = false;

            if(this.playerData.worldData) for(let i = 0; i < this.playerData.worldData.length; i++) this.generateMap(i); // add map back again

        },
        makeNewInventoryItem(y: number, x: number, startEditing = false){
            console.log(y, x);
            const inventoryItem: InventoryItem = {
                name: "Torch",
                amount: 1,
                durability: 100,
                position: { x: x, y: y },
                equipped: false,
                quality: 1,
                variant: 0,
                crafterId:  this.playerData.playerId,
                crafterName: this.playerData.playerName || "Stranger",
                // config: findItemConfig

                isEditing: startEditing
            };
            this.playerData.inventory?.push(inventoryItem);
        },
        deleteInventoryItem(y: number, x: number) {
            if(!this.playerData.inventory) return;
            for (let i = 0; i < this.playerData.inventory.length; i++) {
                if (this.playerData.inventory[i].position.y == y && this.playerData.inventory[i].position.x == x){
                    this.playerData.inventory.splice(i, 1);
                }
            }
            // console.log("deleted item, new inventory", this.playerData.inventory);
        },
        pasteInventoryItem(y: number, x: number){ 
            if(!this.playerData.inventory) return;
            const newItem = JSONBigStrict.parse(JSONBigStrict.stringify(this.copyInventoryItem)) as InventoryItem;
            newItem.position = { x: x, y: y };
            this.playerData.inventory.push(newItem);
            this.copyInventoryItem = undefined;
        },
        refillFood(index: number){
            const def = this.itemConfig[this.playerData.foods[index].name];
            if(!def.m_itemData.m_shared.m_food || !def.m_itemData.m_shared.m_foodStamina) return;
            this.playerData.foods[index].hp = def.m_itemData.m_shared.m_food;
            this.playerData.foods[index].stamina = def.m_itemData.m_shared.m_foodStamina;
        },
        deleteFood(index: number){
            this.playerData.foods.splice(index, 1);
        },
        maxSkill(id: number){
            for (let i = 0; i < this.playerData.skills.length; i++) {
                if(this.playerData.skills[i].id == id){
                    this.playerData.skills[i].level = 99;
                    this.playerData.skills[i].accumulator = 99;
                }
            }
        },
        maxAllSkills(){
            for (let i = 0; i < this.playerData.skills.length; i++) {
                this.maxSkill(this.playerData.skills[i].id);
            }
        },
        deleteWorld(index: number){
            if(!this.playerData.worldData || this.playerData.worldData.length == 0) return;
            this.playerData.worldData.splice(index, 1);
        },
        addFood(ev: Event){

            const form = ev.target as HTMLFormElement;
            const inputs = new FormData(form);

            if(this.playerData.foods.length >= 3){
                alert("Too many active foods");
                ev.preventDefault();
                return false;
            }

            const name = inputs.get("name") as string;

            if(this.playerData.foods.some(e => e.name === name)){
                alert("That food is already active.");
                ev.preventDefault();
                return false;
            }

            this.playerData.foods.push({
                name: name,
                hp: this.itemConfig[name].m_itemData.m_shared.m_food,
                stamina: this.itemConfig[name].m_itemData.m_shared.m_foodStamina,
            });

            ev.preventDefault();
            return false;
        },
        generateMap(num: number){
            if(process.env.NODE_ENV === 'test') return;
            if(!this.playerData.worldData) return;
            // const canvas = this.$refs['worldcanvas-' + num] as HTMLCanvasElement;
            const world = this.playerData.worldData[num];
            if(!world) return;

            const canvas = document.createElement("canvas");
            canvas.width = 2048;
            canvas.height = 2048;
            const ctx = canvas.getContext('2d');
            if(!ctx) return;

            world.worldMapRendering = true;

            const textureSize = world.textureSize.valueOf();

            // const canvasSize = 512;
            const scale = textureSize / this.worldCanvasSize;
            console.log(`texture size: ${textureSize}`);
            console.log(`canvas size: ${this.worldCanvasSize}`);
            console.log(`map scale: ${scale}`);
            
            // draw background
            ctx.fillStyle = 'rgb(0, 0, 0)';
            ctx.fillRect(0, 0, this.worldCanvasSize, this.worldCanvasSize);

            // draw world
            ctx.fillStyle = 'rgb(255, 220, 220)';
            ctx.beginPath();
            ctx.arc(this.worldCanvasSize/2, this.worldCanvasSize/2, this.worldCanvasSize/2, 0, 2 * Math.PI);
            ctx.fill();
            ctx.closePath();

            // draw explored
            ctx.fillStyle = 'rgb(0, 0, 0)';
            const exploredString = new RLE().decode(world.exploredString.toString());
            
            const id = ctx.getImageData(0, 0, 1, 1);
            const d = id.data;
            d[0] = 0;
            d[1] = 0;
            d[2] = 0;
            d[3] = 255;
            
            for (let x = 0; x < textureSize; x++) {
                for (let y = 0; y < textureSize; y++) {
                    if( exploredString.charAt((y * textureSize) + x) === 'X' ){
                        const drawY = textureSize - y;
                        ctx.fillRect(x / scale, drawY / scale, 1, 1);
                        // ctx.putImageData(id, x / scale, drawY / scale);
                    }
                }
            }
            
            world.worldMapRendered = true;
            world.worldMapRendering = false;

            this.generateLeaflet(num, canvas.toDataURL());

        },
        generateLeaflet(num: number, imageUrl: string){
            if(!this.playerData.worldData) return;
            const world = this.playerData.worldData[num];
            if(!world) return;

            console.debug("generate leaflet map");

            const leafletMapDiv = document.getElementById(`worldmap_${num}`);
            if(!leafletMapDiv) return;

            if(world.leafletMap) world.leafletMap.remove();

            world.leafletMap = leaflet.map(`worldmap_${num}`, { crs: leaflet.CRS.Simple, minZoom: -2, maxZoom: 12 }).setView([0, 0], 1);
            
            const bounds = leaflet.latLngBounds(leaflet.latLng(-1024, -1024), leaflet.latLng(1024, 1024));
            
            // explored map
            leaflet.imageOverlay(imageUrl, bounds).addTo(world.leafletMap);

            world.leafletMap.fitBounds(bounds);

            this.placeLeafletMarkers(num);

        },
        placeLeafletMarkers(worldNum: number){
            if(!this.playerData.worldData) return;
            const world = this.playerData.worldData[worldNum];
            if(!world) return;

            console.debug("generate leaflet markers");

            const iconBlue = leaflet.icon({
                iconUrl: 'images/marker-icon.png',
                shadowUrl: 'images/marker-shadow.png',
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize:  [41, 41]
            });

            const iconRed = leaflet.icon({
                iconUrl: 'images/marker-icon-red.png',
                shadowUrl: 'images/marker-shadow.png',
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize:  [41, 41]
            });

            const iconGreen = leaflet.icon({
                iconUrl: 'images/marker-icon-green.png',
                shadowUrl: 'images/marker-shadow.png',
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize:  [41, 41]
            });

            const iconYellow = leaflet.icon({
                iconUrl: 'images/marker-icon-yellow.png',
                shadowUrl: 'images/marker-shadow.png',
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize:  [41, 41]
            });

            // remove old markers
            world.leafletMap.eachLayer((layer: leaflet.Layer) => {
                if((layer as any)._image) return; // don't remove overlay
                layer.remove();
            });

            const calcPos = (x: number, y: number) => {
                const textureSize = world.textureSize;
            
                const worldScale = ( 25_000 / textureSize );

                // const x = ( pos.x / worldScale ) + ( this.worldCanvasSize / 2 );
                // const y = ( ( pos.z * -1 ) / worldScale ) + ( this.worldCanvasSize / 2 );
                const s = 2048 / this.worldCanvasSize;

                return {
                    x: ( x / worldScale / s ),
                    y:  ( ( y ) / worldScale / s )
                };
            }

            const englishPinNames: Record<string, string> = {
                '$enemy_eikthyr': 'Eikthyr',
                '$enemy_gdking': 'The Elder',
                '$enemy_dragon': 'Moder',
            };

            for(const pin of world.pins){
                // const x = pin.position.x / 25000;
                // const y = (pin.position.z * -1) / 25000;
                const pos = calcPos(pin.position.x, pin.position.z);
                
                const marker = leaflet.marker(
                    [pos.y, pos.x], 
                    {
                        title: pin.name,
                        icon: pin.type == 9 ? iconYellow : iconBlue,
                        // draggable: true
                    })
                    .addTo(world.leafletMap);
                
                marker.bindPopup(`<strong>${englishPinNames[pin.name] || pin.name}</strong><br>${this.pinIconNames[pin.type]} (${pin.type})`);
                /*
                marker.addEventListener('moveend', (event: Event) => {
                    console.log("drag end", pin.name, event);
                });
                */
            }
            
            const homePos = calcPos(world.homePoint.x, world.homePoint.z);
            const homeMarker = leaflet.marker([homePos.y, homePos.x], { title: "Home", icon: iconGreen }).addTo(world.leafletMap);
            homeMarker.bindPopup("<strong>Home</strong>");

            if(world.hasDeathPoint){
                const deathPos = calcPos(world.deathPoint.x, world.deathPoint.z);
                const deathMarker = leaflet.marker([deathPos.y, deathPos.x], { title: "Death", icon: iconRed }).addTo(world.leafletMap);
                deathMarker.bindPopup("<strong>Death</strong>");
            }

            if(world.hasLogoutPoint){
                const logoutPos = calcPos(world.logoutPoint.x, world.logoutPoint.z);
                const logoutMarker = leaflet.marker([logoutPos.y, logoutPos.x], { title: "Logout", icon: iconGreen }).addTo(world.leafletMap);
                logoutMarker.bindPopup("<strong>Logout</strong>");
            }

            console.debug("leaflet map markers generated");

        },
        exploreFullMap(num: number){
            if(!this.playerData.worldData) return;
            const world = this.playerData.worldData[num];
            if(!world) return;
            /*
            for (let x = 0; x < world.textureSize; x++) {
                for (let y = 0; y < world.textureSize; y++) {
                    world.exploredGrid[x][y] = true;
                }
            }
            */
            // world.exploredString = new RLE().encode(pogo);
            // let fullMap = "OOO";
            // fullMap += "X".repeat( (world.textureSize*world.textureSize) - 3);
            // 
            // world.exploredString = new RLE().encode(fullMap);
            world.exploredString = `{${world.textureSize*world.textureSize}}X`;
            // console.log(world.exploredString);
            this.generateMap(num);
        },
        worldCanvasPinStyle(pos: Vector3){
            
            const textureSize = ( this.playerData.worldData ? this.playerData.worldData[0].textureSize : 2048 );
            
            const worldScale = ( 25_000 / textureSize );

            // const x = ( pos.x / worldScale ) + ( this.worldCanvasSize / 2 );
            // const y = ( ( pos.z * -1 ) / worldScale ) + ( this.worldCanvasSize / 2 );
            const s = 2048 / this.worldCanvasSize;

            const x = ( pos.x / worldScale / s ) + ( this.worldCanvasSize / 2 );
            const y = ( ( pos.z * -1 ) / worldScale / s ) + ( this.worldCanvasSize / 2 );

            return {
                top: `${y}px`,
                left: `${x}px`
            }
        },
        inventoryManage(ev: SubmitEvent){
            const form = ev.target as HTMLFormElement;
            const inputs = new FormData(form);

            const method = ev.submitter.value;
            const slot = inputs.get("slot") as string;

            console.log(method, slot);

            if( method == "export" ){
                const copy = Object.assign({}, this.playerData.inventory);
                this.inventoryBackups[slot] = copy;
                console.log("backups", this.inventoryBackups);
            }else if( method == "import" ){
                if(!this.inventoryBackups[slot]){
                    alert("No such slot");
                    ev.preventDefault();
                    return false;
                }
                console.log("import slot", this.inventoryBackups[slot]);
                // this.playerData.inventory = {...this.inventoryBackups[slot]};
                this.playerData.inventory = Object.assign({}, this.inventoryBackups[slot]);
            }

            ev.preventDefault();
            return false;
        },
        doBackup(data: PlayerData){
            
            const backups = localStorage.playerDataBackups ? JSONBig.parse(localStorage.playerDataBackups) : [];
            
            const crc = crc32(JSONBig.stringify(data));
            
            for(const oldBackup of backups){
                if(oldBackup.crc === crc){
                    console.log("already has backup", crc);
                    return;
                }
            }

            backups.push({
                name: data.playerName,
                crc: crc,
                data: data
            });

            console.log("backed up", crc);

            try {
                localStorage.playerDataBackups = JSONBig.stringify(backups);
            } catch (error) {
                console.error("couldn't back up", crc, error);
            }
            
        }
    },
    computed: {
        /**
         * Output a full inventory with x/y and empty slots
         */
        formattedInventory(): InventoryItem[][] | InventoryItemEmpty[][] {
            const inv = [];
            for(let y = 0; y < 4; y++){
                const row = [];
                for(let x = 0; x < 8; x++){
                    let found = false;
                    if(this.playerData.inventory){
                        // console.log(this.playerData.inventory);
                        for(const tmpItem of this.playerData.inventory){
                            if (tmpItem && tmpItem.position && tmpItem.position.x == x && tmpItem.position.y == y){
                                row.push(tmpItem);
                                found = true;
                                break;
                            }
                        }
                    }
                    if(!found){
                        row.push({ empty: true });
                    }
                    
                }
                inv.push(row);
            }
            return inv;
        },
        hairColour: {
            get(): string {
                return rgbToHex(Math.round(this.playerData.hairColour.r), Math.round(this.playerData.hairColour.g), Math.round(this.playerData.hairColour.b));
            },
            set(val: string): void {
                this.playerData.hairColour = hexToRgb(val);
            }
        },
        skinColour: {
            get(): string {
                return rgbToHex(Math.round(this.playerData.skinColour.r), Math.round(this.playerData.skinColour.g), Math.round(this.playerData.skinColour.b));
            },
            set(val: string): void {
                this.playerData.skinColour = hexToRgb(val);
            }
        },
        sortedItems(): MItem[] {
            return this.gameObjects
                .slice() // clone array
                .sort((a, b) => a.m_itemData.m_shared.m_name_EN.localeCompare(b.m_itemData.m_shared.m_name_EN));
        },
        pickableInventoryItemsSorted(): { value: string; label: string; data: MItem }[] {
            // const ignoredTypes = ['Customization'];
            /*
            return this.sortedItems
                .filter((item) => ignoredTypes.indexOf(item.m_itemData.m_shared.m_itemType) === -1 );
                */
            const data = [];
            for(const i in this.sortedItems){
                const item = this.sortedItems[i];
                data.push({
                    value: item.name,
                    label: item.m_itemData.m_shared.m_name_EN,
                    data: item
                });
            }
            return data;
        },
        foodItems(): MItem[] {
            return this.gameObjects
                .filter(a => a.m_itemData.m_shared.m_food > 0 )
                // .filter(a => a.m_itemData.m_shared.m_itemType === "Consumable" )
                .slice() // clone array
                .sort((a, b) => a.m_itemData.m_shared.m_name_EN.localeCompare(b.m_itemData.m_shared.m_name_EN));
        },
        inventoryWeight(): number {
            if(!this.playerData.inventory) return 0;
            return this.playerData.inventory.reduce(
                (a, b) => a + this.itemConfig[b.name].m_itemData.m_shared.m_weight * b.amount,
                0
            );
        },
        inventoryArmor(): number {
            if(!this.playerData.inventory || this.playerData.inventory.length == 0) return 0;
            let total = 0;
            const eligible = ["Legs", "Chest", "Shoulder"]
            for (const item of this.playerData.inventory){
                if( item === undefined ){ console.error("undefined item"); continue; }
                if (
                    item.equipped &&
                    this.itemConfig[item.name].m_itemData.m_shared.m_armor &&
                    eligible.indexOf(this.itemConfig[item.name].m_itemData.m_shared.m_itemType) !== -1
                ){
                    total += this.itemConfig[item.name].m_itemData.m_shared.m_armor;
                }
            }
            return total;
        }
    }
});
</script>

<style lang="scss">

@import 'bulma/bulma';
@import 'bulma-slider';
@import "~leaflet/dist/leaflet.css";

@import "@vueform/multiselect/themes/default.scss";

/*
.inventory-table {
    td {
        width: 12.5%;
    }
}
*/

details {
    summary {
        cursor: pointer;
    }
}

.inventory-slot-full {
    cursor: pointer;
    position: relative;
    &:hover {
        .tooltip { display: block; }
    }
}

.tooltip {
    pointer-events: none; // hmm
    z-index: 1;
    display: none;
    font-size: 0.8em;
    padding: 2px 5px;
    position: absolute;
    top: 100%;
    width: 150%;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, .9);
    color: #fff;
    word-wrap: break-word;
    p { font-size: 0.9em; padding-bottom: 3px; }
    strong { color: #fff; }
}

.inventory-slot-name {
    font-weight: 700;
}

.inventory-slot-amount {
    font-size: 0.9em;
    color: #666;
}

.worldmap {
    width: 100%;
    height: 512px;
}

.worldcanvas {
    position: relative;
    .pin {
        position: absolute;
        .pin-icon {
            width: 4px;
            height: 4px;
            background-color: #f00;
            border-radius: 100%;
            cursor: pointer;
            transform: translate(-2px, -2px);
            &:hover + .pin-label {
                display: block;
            }
        }
        .pin-label {
            pointer-events: none;
            display: none;
            font-size: 10px;
            color: #fff;
            padding: 0 4px;
            background-color: rgba(0, 0, 0, .9);
            border-radius: 2px;
            transform: translate(-50%, 20px);
        }
        // &:hover {
        //     .pin-label { display: block; }
        // }
    }
}
/*
.export-form {
    position: sticky;
    bottom: 0px;
    background: #fff;
    border-top: 1px solid #eee;
    padding: 15px;
}
*/
</style>
