
// vue
import { defineComponent, nextTick } from 'vue';
import Multiselect from '@vueform/multiselect';

// ts
// import JSONBig { useNativeBigInt: true } from 'json-bigint';
import JSONBig from 'json-bigint';
const JSONBigStrict = JSONBig({ useNativeBigInt: true });
// const JSONBig = require('json-bigint')({ useNativeBigInt: true });

import BinaryReader from './libs/BinaryReader';
import RLE from './libs/RLE';
import { sha512 } from 'js-sha512'; /** @todo: implement */
import { crc32 } from 'crc';

import leaflet from 'leaflet';

// import pogo from './data/pogo';

// const { subtle } = require('crypto').webcrypto;

// game data
import gameObjects from './data/itemdrops.json';
import gameRecipes from './data/recipes.json';
import { InventoryItem, PlayerData, WorldDataEntry, Vector3, MItem, SubmitEvent, InventoryItemEmpty, MRecipe } from '@/valheim';

const skillNames = {
    "2": "Knives",
    "3": "Clubs",
    "6": "Blocking",
    "7": "Axes",
    "8": "Bows",
    "11": "Unarmed",
    "12": "Pickaxes",
    "13": "Woodcutting",

    "100": "Jump",
    "101": "Sneaking",
    "102": "Running",
    "103": "Swim",
};

const biomeNames = {
    "0": "None",
    "1": "Meadows",
    "2": "Swamp",
    "4": "Mountain",
    "8": "BlackForest",
    "16": "Plains",
    "32": "AshLands",
    "64": "DeepNorth",
    "256": "Ocean",
    "512": "Mistlands",
    "513": "BiomesMax",
}

const pinIconNames = {
    "0": "Fire",
    "1": "Icon1",
    "2": "Icon2",
    "3": "Dot",
    "4": "Death",
    "5": "Bed",
    "6": "Icon4",
    "7": "Shout",
    "8": "None",
    "9": "Boss",
    "10": "Player",
    "11": "RandomEvent",
    "12": "Ping",
    "13": "EventArea"
};

function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : { r: 0, g: 0, b: 0 };
}

/**
 * Thanks to balu92 for the original c# code and item dumps
 */

export default defineComponent({
    name: 'App',
    $refs: {
        addFoodHp: {} as HTMLInputElement,
        addFoodStamina: {} as HTMLInputElement,
    },
    data: () => {
        return {
            APP_VERSION: process.env.VUE_APP_VERSION,
            supportedVersion: {
                player: 33,
                miniMap: 4,
                playerData: 24,
                inventory: 103,
                skills: 2,
            },
            buffer: {} as ArrayBuffer,
            dataview: {} as DataView,
            pointer: 0,
            playerData: {} as PlayerData,
            gameObjects: gameObjects as MItem[],
            gameRecipes: gameRecipes as MRecipe[],
            unsupportedVersion: false,
            skillNames: skillNames as Record<string, string>,
            pinIconNames: pinIconNames as Record<string, string>,
            biomeNames: biomeNames as Record<string, string>,
            itemConfig: {} as Record<string, MItem>,
            recipeConfig: {} as Record<string, MRecipe>,
            isExporting: false,
            worldCanvasSize: 2048,
            inventoryBackups: {} as Record<string, InventoryItem[]>,
            imacheater: false,
            copyInventoryItem: undefined as InventoryItem | undefined,
            playerBeards: [
                "BeardNone",
                "Beard1",
                "Beard2",
                "Beard3",
                "Beard4",
                "Beard5",
                "Beard6",
                "Beard7",
                "Beard8",
                "Beard9",
                "Beard10", 
            ],
            playerHairs: [
                "HairNone",
                "Hair1",
                "Hair2",
                "Hair3",
                "Hair4",
                "Hair5",
                "Hair6",
                "Hair7",
                "Hair8",
                "Hair9",
                "Hair10",
                "Hair11",
                "Hair12",
                "Hair13",
                "Hair14",
            ]
        };
    },
    components: {
        Multiselect
    },
    mounted() {
        for(const item of this.gameObjects){
            this.itemConfig[item.name] = item;
        }
        for(const recipe of this.gameRecipes){
            this.recipeConfig[recipe.name.substring(7)] = recipe;
        }
        if(localStorage.inventoryBackups){
            this.inventoryBackups = JSONBig.parse(localStorage.inventoryBackups || {});
        }
    },
    watch: {
        inventoryBackups: {
            deep: true,
            handler(newData){
                localStorage.inventoryBackups = JSONBig.stringify(newData);
                console.log("Inventory backups saved");
            }
        }
    },
    methods: {
        handleFile(event: InputEvent){
            const input = event.target as HTMLInputElement;
            if(!input || !input.files) return;
            console.log("handleFile");
            const file = input.files[0];
            console.log("file", file);
            const fileURL = URL.createObjectURL(file);
            console.log("fileURL", fileURL);
            
            const reader = new FileReader();
            reader.onload = (evt) => {

                const buffer = evt.target?.result as ArrayBuffer;

                if(!buffer){
                    console.error("no buffer");
                    return;
                }

                this.doImport(buffer);

            }

            reader.readAsArrayBuffer(file);

            console.log(this.playerData);            

        },
        doImport(buffer: ArrayBuffer) {

            this.unsupportedVersion = false;

            const binReader = new BinaryReader(buffer);
                
            this.playerData.saveDataLength = binReader.ReadInt32();

            this.playerData.dataVersions = {};

            this.playerData.dataVersions.player = binReader.ReadInt32("player data version");
            this.playerData.playerKills = binReader.ReadInt32("player kills");
            this.playerData.playerDeaths = binReader.ReadInt32("player deaths");
            this.playerData.playerCrafts = binReader.ReadInt32("player crafts");
            this.playerData.playerBuilds = binReader.ReadInt32("player builds");

            if(this.playerData.dataVersions.player !== this.supportedVersion.player){
                this.unsupportedVersion = true;
                // console.warn("unsupported version", "player", this.playerData.dataVersions.player);
            }

            const worldDataCount = binReader.ReadInt32("world data count") || 0;
            // console.log(`Number of world data: ${worldDataCount}`);

            this.playerData.worldData = [];
            for (let i = 0; i < worldDataCount; i++) {
                
                const worldDataEntry: WorldDataEntry = {
                    worldDataID: binReader.ReadUInt64("world data id"),
                    hasCustomSpawnPoint: binReader.ReadBoolean("has custom spawn point"),
                    spawnPoint: binReader.ReadVector3("world spawn point"),
                    hasLogoutPoint: binReader.ReadBoolean("has world logout point"),
                    logoutPoint: binReader.ReadVector3("world logout point"),
                    hasDeathPoint: binReader.ReadBoolean("world has death point"),
                    deathPoint: binReader.ReadVector3("world death point"),
                    homePoint: binReader.ReadVector3("world home point"),
                    hasMapData: binReader.ReadBoolean("world has map data"),
                    // exploredGrid: [],
                    exploredString: "",
                    pins: [],
                    textureSize: -1,
                    miniMapMapversion: -1,
                    exploredPercent: -1
                };

                // console.log(`HasMapData: ${worldDataEntry.hasMapData}`);
                if (worldDataEntry.hasMapData) {
                    
                    const mapDataLength = binReader.ReadInt32("mapData length");
                    const mapData = binReader.ReadBytes(mapDataLength);

                    // console.log("mapDataLength", mapDataLength);

                    if (mapData) {
                        
                        //using var worldMemory = new MemoryStream(mapData);
                        const worldReader = new BinaryReader(mapData);

                        worldDataEntry.miniMapMapversion = worldReader.ReadInt32("minimap version");

                        if(worldDataEntry.miniMapMapversion !== this.supportedVersion.miniMap){
                            this.unsupportedVersion = true;
                            // console.warn("unsupported version", "miniMap");
                        }

                        worldDataEntry.textureSize = worldReader.ReadInt32("minimap texture size");
                        // console.log(`TextureSize: ${worldDataEntry.textureSize}`);

                        // worldDataEntry.exploredGrid = [];
                        
                        let explored = 0;
                        let tmpExplored = "";
                        for (let x = 0; x < worldDataEntry.textureSize; x++) {
                            // const row = [];
                            for (let y = 0; y < worldDataEntry.textureSize; y++) {
                                // pixel at X:Y is explored by the player on this map
                                const isExplored = worldReader.ReadBoolean();
                                if (isExplored) explored++;
                                // row.push(isExplored);
                                tmpExplored += isExplored ? 'X' : 'O';
                            }
                            // worldDataEntry.exploredGrid.push(row);
                        }
                        // console.log(tmpExplored);
                        worldDataEntry.exploredString = new RLE().encode(tmpExplored);
                        // worldDataEntry.exploredString = LZString.compress(tmpExplored);
                        // console.log(worldDataEntry.exploredString);

                        worldDataEntry.exploredPercent = explored / (worldDataEntry.textureSize * worldDataEntry.textureSize);

                        // not really, since you can't explore the corners
                        // console.log(`${worldDataEntry.exploredPercent*100}% explored!`);

                        worldDataEntry.pins = [];
                        
                        const numberOfPins = worldReader.ReadInt32("number of pins");
                        // console.log(`Number of player pins: ${numberOfPins}`);
                        for (let j = 0; j < numberOfPins; j++) {
                            const pin = {
                                name: worldReader.ReadString(`pin ${j} name`),
                                position: worldReader.ReadVector3(),
                                // position: { x: worldReader.ReadSingle(), y: worldReader.ReadSingle(), z: worldReader.ReadSingle() },
                                type: worldReader.ReadInt32(`pin ${j} type`),
                                checked: worldReader.ReadBoolean(`pin ${j} checked`) // has X when the player right-clicks it
                            };
                            worldDataEntry.pins.push(pin);
                        }

                        if( worldDataEntry.miniMapMapversion >= 4){
                            worldDataEntry.showPlayerOnMap = worldReader.ReadBoolean();
                        }
                    }
                    
                }

                this.playerData.worldData.push(worldDataEntry);

            }

            /** @todo: read 7-bit integer or something */
            this.playerData.playerName = binReader.ReadString("player name", true);
            // this.playerData.playerName = unescape(encodeURIComponent(this.playerData.playerName));

            // console.log(`Player's name ${this.playerData.playerName}`);

            this.playerData.playerId = binReader.ReadUInt64("player id");
            // console.log(`Player's generated ID ${this.playerData.playerId}`);
            
            this.playerData.startSeed = binReader.ReadString("start seed");
            // console.log(`Start seed ${this.playerData.startSeed}`);

            this.playerData.hasPlayerData = binReader.ReadBoolean();
            // console.log(`Has Player Data: ${this.playerData.hasPlayerData}`);

            if (this.playerData.hasPlayerData) {

                const playerDataLength = binReader.ReadInt32();
                const playerData = binReader.ReadBytes(playerDataLength);

                if (playerData) {

                    // using var playerMemory = new MemoryStream(playerData);
                    const playerReader = new BinaryReader(playerData);

                    this.playerData.dataVersions.playerData = playerReader.ReadInt32();
                    // console.log(`\tPlayer DataVersion: ${this.playerData.playerDataVersion}`);
                    if(this.playerData.dataVersions.playerData !== this.supportedVersion.playerData){
                        this.unsupportedVersion = true;
                        // console.warn("unsupported version", "playerData");
                    }

                    this.playerData.playerMaxHealth = playerReader.ReadSingle();
                    // console.log(`\tMax Health: ${this.playerData.playerMaxHealth}`);
                    this.playerData.playerHealth = playerReader.ReadSingle();
                    // console.log(`\tHealth: ${this.playerData.playerHealth}`);
                    this.playerData.playerStamina = playerReader.ReadSingle();
                    // console.log(`\tStamina: ${this.playerData.playerStamina}`);

                    this.playerData.firstSpawn = playerReader.ReadBoolean();
                    // console.log(`\tFirst spawn: ${this.playerData.firstSpawn}`);

                    this.playerData.timeSinceDeath = playerReader.ReadSingle()
                    // console.log(`\tTime since death: ${this.playerData.timeSinceDeath}`);

                    this.playerData.guardianPower = playerReader.ReadString();
                    // console.log(`\tGuardian power: ${this.playerData.guardianPower}`);

                    this.playerData.guardianPowerCooldown = playerReader.ReadSingle()
                    // console.log(`\tGuardian power CD: ${this.playerData.guardianPowerCooldown}`);

                    this.playerData.dataVersions.inventory = playerReader.ReadInt32();
                    // console.log(`\tInventory DataVersion: ${this.playerData.inventoryDataVersion}`);
                    if(this.playerData.dataVersions.inventory !== this.supportedVersion.inventory){
                        this.unsupportedVersion = true;
                        // console.warn("unsupported version", "inventory");
                    }

                    // inventory
                    const inventoryItemCount = playerReader.ReadInt32();
                    // console.log(`\tNumber of items in inventory: ${inventoryItemCount}`);
                    this.playerData.inventory = [];
                    for (let i = 0; i < inventoryItemCount; i++) {
                        /*
                        console.log(`\tName: ${playerReader.ReadString()} (x${playerReader.ReadInt32()})\n`);
                        console.log(`\t\tDurability: ${playerReader.ReadSingle()}\n`);
                        console.log(`\t\tPosition: Column:${playerReader.ReadInt32()}, Row:${playerReader.ReadInt32()}\n`);
                        console.log(`\t\tIsEquiped: ${playerReader.ReadBoolean()}\n`);
                        console.log(`\t\tQuality: ${playerReader.ReadInt32()}\n`);
                        console.log(`\t\tVariant: ${playerReader.ReadInt32()}\n`);
                        console.log(`\t\tCrafter: ${playerReader.ReadInt64()} (${playerReader.ReadString()})\n`);
                        */

                        const inventoryItem: InventoryItem = {
                            name: playerReader.ReadString(),
                            amount: playerReader.ReadInt32(),
                            durability: playerReader.ReadSingle(),
                            position: { x: playerReader.ReadInt32(), y: playerReader.ReadInt32() },
                            equipped: playerReader.ReadBoolean(),
                            quality: playerReader.ReadInt32(),
                            variant: playerReader.ReadInt32(),
                            crafterId: playerReader.ReadUInt64(),
                            crafterName: playerReader.ReadString("inventory item crafter name", true),
                        };

                        this.playerData.inventory.push(inventoryItem);
                    }

                    // known
                    const knownRecipesCount = playerReader.ReadInt32("known recipes count");
                    // console.log(`\tKnown recipes: ${knownRecipesCount}`);
                    this.playerData.knownRecipes = [];
                    for (let i = 0; i < knownRecipesCount; i++) {
                        this.playerData.knownRecipes.push(playerReader.ReadString());
                        // console.log(`\t\t ${playerReader.ReadString()}`);
                    }

                    const knownStationsCount = playerReader.ReadInt32("known stations count");
                    // console.log(`\tKnown stations: ${knownStationsCount}`);
                    this.playerData.knownStations = {};
                    for (let i = 0; i < knownStationsCount; i++) {
                        this.playerData.knownStations[ playerReader.ReadString() ] = playerReader.ReadInt32();
                        // console.log(`\t\t${i}: ${playerReader.ReadString()} (LVL: ${playerReader.ReadInt32()})`);
                    }

                    const knownMaterialCount = playerReader.ReadInt32("known material count");
                    // console.log(`\tKnown materials: ${knownMaterialCount}`);
                    this.playerData.knownMaterials = [];
                    for (let i = 0; i < knownMaterialCount; i++) {
                        this.playerData.knownMaterials.push(playerReader.ReadString());
                        // console.log(`\t\t${playerReader.ReadString()}`);
                    }

                    const knownTutorialCount = playerReader.ReadInt32("known tutorial count");
                    // console.log(`\tKnown tutorials: ${knownTutorialCount}`);
                    this.playerData.knownTutorials = [];
                    for (let i = 0; i < knownTutorialCount; i++) {
                        this.playerData.knownTutorials.push(playerReader.ReadString());
                        // console.log(`\t\t${playerReader.ReadString()}`);
                    }

                    const uniquesCount = playerReader.ReadInt32("uniques count");
                    // console.log(`\tUniques: ${uniquesCount}`);
                    this.playerData.knownUniques = [];
                    for (let i = 0; i < uniquesCount; i++) {
                        this.playerData.knownUniques.push(playerReader.ReadString());
                        // console.log(`\t\t${playerReader.ReadString()}`);
                    }

                    const triophiesCount = playerReader.ReadInt32("trophies count");
                    // console.log(`\tTrophies: ${triophiesCount}`);
                    this.playerData.knownTrophies = [];
                    for (let i = 0; i < triophiesCount; i++) {
                        this.playerData.knownTrophies.push(playerReader.ReadString());
                        // console.log(`\t\t${playerReader.ReadString()}`);
                    }

                    const knownBiomes = playerReader.ReadInt32("known biomes amount");
                    // console.log(`\tKnown biomes: ${knownBiomes}`);
                    this.playerData.knownBiomes = [];
                    for (let i = 0; i < knownBiomes; i++) {
                        const biome = playerReader.ReadInt32();
                        this.playerData.knownBiomes.push(biome);
                        // console.log(`\t\t[${biome}] + ${biome}`);
                    }

                    const knownTexts = playerReader.ReadInt32("known texts amount");
                    // console.log(`\tKnown Texts: ${knownTexts}`);
                    this.playerData.knownTexts = {};
                    for (let i = 0; i < knownTexts; i++) {
                        const k = playerReader.ReadString();
                        const v = playerReader.ReadString();
                        this.playerData.knownTexts[k] = v;
                        // console.log(`\t\t${playerReader.ReadString()}: ${playerReader.ReadString()}`);
                    }

                    // looks
                    this.playerData.beard = playerReader.ReadString("beard");
                    // console.log(`\tBeard: ${this.playerData.beard}`);
                    this.playerData.hair = playerReader.ReadString("hair");
                    // console.log(`\tHair: ${this.playerData.hair}`);
                    
                    this.playerData.skinColour = playerReader.ReadColour("skin colour");
                    this.playerData.hairColour = playerReader.ReadColour("hair colour");

                    // console.log(`\tSkin colour: rgb(${Math.round(playerReader.ReadSingle() * 255)},${Math.round(playerReader.ReadSingle() * 255)},${Math.round(playerReader.ReadSingle() * 255)})`);
                    // console.log(`\tHair colour: rgb(${Math.round(playerReader.ReadSingle() * 255)},${Math.round(playerReader.ReadSingle() * 255)},${Math.round(playerReader.ReadSingle() * 255)})`);
                    this.playerData.modelIndex = playerReader.ReadInt32("model index");
                    // console.log(`\tModel index: ${this.playerData.modelIndex}`);

                    if(this.playerData.beard.length > 20){
                        alert("This character file uses an unsupported third party modification.");
                        this.playerData = {} as PlayerData;
                        return;
                    }

                    // Food
                    const foodCount = playerReader.ReadInt32("foodCount");

                    if(foodCount > 3){
                        throw `More than 3 foods read (${foodCount})`;
                    }
                    // console.log(`\tFoods: ${foodCount}`);
                    this.playerData.foods = [];
                    for (let i = 0; i < foodCount; i++) {
                        const food = {
                            name: playerReader.ReadString("food name"),
                            hp: playerReader.ReadSingle("food hp"),
                            stamina: playerReader.ReadSingle("food stamina")
                        };
                        this.playerData.foods.push(food);
                        // console.log(`\t\t${playerReader.ReadString()}: \r\n\t\t\tHP: ${playerReader.ReadSingle()}\r\n\t\t\tStamina: ${playerReader.ReadSingle()}`);
                    }

                    // Skills
                    this.playerData.dataVersions.skills = playerReader.ReadInt32("skills dataVersion");
                    // console.log(`Skills DataVersion: ${this.playerData.skillsDataVersion}`);
                    if(this.playerData.dataVersions.skills !== this.supportedVersion.skills){
                        this.unsupportedVersion = true;
                        // console.warn("unsupported version", "skills");
                    }

                    const skillCount = playerReader.ReadInt32();
                    // console.log(`\tSkills: ${skillCount}`);
                    this.playerData.skills = [];
                    for (let i = 0; i < skillCount; i++) {
                        const skill = {
                            id: playerReader.ReadInt32(),
                            level: playerReader.ReadSingle(),
                            accumulator: playerReader.ReadSingle()
                        };
                        this.playerData.skills.push(skill);
                        // console.log(`\t\t${playerReader.ReadInt32()}: \r\n\t\t\tLevel: ${playerReader.ReadSingle()}\r\n\t\t\tAccumulator: ${playerReader.ReadSingle()}`);
                    }

                }

            }

            // console.debug(`Hash start: ${binReader.tell()}`);
            this.playerData.hashStart = binReader.tell();
            this.playerData.hashLength = binReader.ReadInt32("get hash length");

            // const hash = BitConverter.ToString(binReader.ReadBytes(hashLength)).Replace("-", "");
            // const dec = new TextDecoder("iso-8859-2");
            const hashRaw = binReader.ReadBytes(this.playerData.hashLength);
            // const hashRaw = binReader.ReadByteArray();
            if(hashRaw){
                this.playerData.hashRaw = hashRaw;
                
                // const hashArray = Array.from(new Uint8Array(this.playerData.hashRaw));                     // convert buffer to byte array
                // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                // console.log("hash", this.playerData.hashRaw, hashHex);

                // this.playerData.hash = dec.decode(this.playerData.hashRaw);
                // console.debug(`HASH: ${this.playerData.hash}`);
            }

            this.doBackup(this.playerData);
            
            nextTick(() => {
                if(this.playerData.worldData) for(let i = 0; i < this.playerData.worldData.length; i++ ) this.generateMap(i);
            });

            // console.debug(this.playerData);

        },
        doExport(){
            
            let finalBuffer;
            
            try {
                finalBuffer = this.doExportBinary();
            } catch (error) {
                alert(`Export error: ${error}`);
                this.isExporting = false;
                return;
            }

            if(!finalBuffer){
                console.error("no buffer");
                return;
            }
            const blob = new Blob([finalBuffer], { type: "application/octet-stream"});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.playerData.playerName?.toLowerCase()}.fch`;
            link.click();
        },
        doExportBinary() {

            if(!this.playerData) return;

            this.isExporting = true;

            // const start = new Date().getTime();

            // console.debug(new Date().getTime() - start, "Start export");
            const buffer = new ArrayBuffer( (this.playerData.saveDataLength || 8_000_000 ) + (1_000_000) ); // can't resize buffers?
            const binWriter = new BinaryReader(buffer);
            
            // console.debug(new Date().getTime() - start, "Start data");
            binWriter.skip(4);

            const dataStartOffset = binWriter.tell();

            binWriter.WriteInt32(this.playerData.dataVersions.player, "playerVersion");
            binWriter.WriteInt32(this.playerData.playerKills, "playerKills");
            binWriter.WriteInt32(this.playerData.playerDeaths, "playerDeaths");
            binWriter.WriteInt32(this.playerData.playerCrafts, "playerCrafts");
            binWriter.WriteInt32(this.playerData.playerBuilds, "playerBuilds");

            binWriter.WriteInt32(this.playerData.worldData?.length || 0, "worldData length"); // count

            // console.debug(new Date().getTime() - start, "Start export world data");
            if (this.playerData.worldData){
                // const localWorldData = this.playerData.worldData.slice(); // perf
                // const localWorldData = JSONBig.parse(JSONBig.stringify(this.playerData.worldData));
                // console.log(localWorldData);
                for (let i = 0; i < this.playerData.worldData.length; i++) {

                    // make local copy to not make vue take control
                    const worldDataEntry = Object.assign({}, this.playerData.worldData[i]);
                    // const worldDataEntry = JSON.parse(JSON.stringify(this.playerData.worldData[i]));

                    binWriter.WriteUInt64(worldDataEntry.worldDataID, "world data id");
                    binWriter.WriteBoolean(worldDataEntry.hasCustomSpawnPoint, "hasCustomSpawnPoint");
                    binWriter.WriteVector3(worldDataEntry.spawnPoint, "spawnPoint");
                    binWriter.WriteBoolean(worldDataEntry.hasLogoutPoint, "hasLogoutPoint");
                    binWriter.WriteVector3(worldDataEntry.logoutPoint, "logoutPoint");
                    binWriter.WriteBoolean(worldDataEntry.hasDeathPoint, "hasDeathPoint");
                    binWriter.WriteVector3(worldDataEntry.deathPoint, "deathPoint");
                    binWriter.WriteVector3(worldDataEntry.homePoint, "homePoint");

                    binWriter.WriteBoolean(worldDataEntry.hasMapData, "hasMapData");

                    if (worldDataEntry.hasMapData) {

                        const mapDataLengthOffset = binWriter.tell();
                        binWriter.skip(4, "mapDataLengthOffset placeholder");
                        
                        binWriter.WriteInt32(worldDataEntry.miniMapMapversion, "minimap version");

                        // write explored map
                        binWriter.WriteInt32(worldDataEntry.textureSize, "texture size");
                        const exploredString = new RLE().decode(worldDataEntry.exploredString.toString());
                        for (let x = 0; x < worldDataEntry.textureSize; x++) {
                            for (let y = 0; y < worldDataEntry.textureSize; y++) {
                                binWriter.WriteBoolean(exploredString.substr( (x * worldDataEntry.textureSize) + y, 1 ) === 'X');
                            }
                        }

                        // write map pins
                        binWriter.WriteInt32(worldDataEntry.pins.length, "pins count");
                        if (worldDataEntry.pins.length > 0){
                            for (let j = 0; j < worldDataEntry.pins.length; j++) {
                                const pin = worldDataEntry.pins[j];
                                if(!pin){
                                    throw new Error(`Invalid pin ${j}`);
                                }
                                binWriter.WriteString(pin.name, "pin name");
                                binWriter.WriteVector3(pin.position, "pin position");
                                binWriter.WriteInt32(pin.type, "pin type");
                                binWriter.WriteBoolean(pin.checked, "pin checked"); // has X when the player right-clicks it
                            }
                        }

                        if( worldDataEntry.miniMapMapversion >= 4){
                            binWriter.WriteBoolean(worldDataEntry.showPlayerOnMap || false);
                        }

                        const tmpOffset = binWriter.tell();
                        binWriter.seek(mapDataLengthOffset);
                        binWriter.WriteInt32(Math.abs(mapDataLengthOffset-tmpOffset)-4, "mapDataLengthOffset");
                        binWriter.seek(tmpOffset);
                    }

                }
            }

            binWriter.WriteString(this.playerData.playerName || "Stranger", "Player's name", true);
            binWriter.WriteUInt64(this.playerData.playerId, "Player's generated ID");
            binWriter.WriteString(this.playerData.startSeed, "Start seed");
            binWriter.WriteBoolean(this.playerData.hasPlayerData, "Has Player Data");

            let playerDataOffsetStart = 0;
            // let playerDataOffsetEnd = 0;

            // console.debug(new Date().getTime() - start, "Start export player data");
            if (this.playerData.hasPlayerData) {

                // const playerDataLength = binReader.ReadInt32();
                // const playerData = binReader.ReadBytes(playerDataLength);
                playerDataOffsetStart = binWriter.tell();
                binWriter.skip(4, "playerDataOffsetStart");

                //if (playerData) {

                    // using var playerMemory = new MemoryStream(playerData);
                    //const playerReader = new BinaryReader(playerData);

                    binWriter.WriteInt32(this.playerData.dataVersions.playerData, "player data version");
                    //console.log(`\tPlayer DataVersion: ${this.playerData.playerDataVersion}`);

                    binWriter.WriteSingle(this.playerData.playerMaxHealth, "player max health");
                    //console.log(`\tMax Health: ${this.playerData.playerMaxHealth}`);

                    binWriter.WriteSingle(this.playerData.playerHealth, "player health");
                    //console.log(`\tHealth: ${this.playerData.playerHealth}`);

                    binWriter.WriteSingle(this.playerData.playerStamina, "player stamina");
                    //console.log(`\tStamina: ${this.playerData.playerStamina}`);

                    binWriter.WriteBoolean(this.playerData.firstSpawn, "player first spawn");
                    //console.log(`\tFirst spawn: ${this.playerData.firstSpawn}`);

                    binWriter.WriteSingle(this.playerData.timeSinceDeath, "player time since death")
                    //console.log(`\tTime since death: ${this.playerData.timeSinceDeath}`);

                    binWriter.WriteString(this.playerData.guardianPower, "player guardian power");
                    //console.log(`\tGuardian power: ${this.playerData.guardianPower}`);

                    binWriter.WriteSingle(this.playerData.guardianPowerCooldown, "player guardian power cooldown")
                    //console.log(`\tGuardian power CD: ${this.playerData.guardianPowerCooldown}`);

                    binWriter.WriteInt32(this.playerData.dataVersions.inventory, "inventory data version");
                    //console.log(`\tInventory DataVersion: ${this.playerData.inventoryDataVersion}`);

                    // inventory
                    binWriter.WriteInt32(this.playerData.inventory?.length || 0, "inventory item count");
                    //console.log(`\tNumber of items in inventory: ${inventoryItemCount}`);
                    if(this.playerData.inventory){
                        if(this.playerData.inventory.length > 100){
                            throw new Error("Inventory too big");
                        }
                        for (let i = 0; i < this.playerData.inventory.length; i++) {
                            const inventoryItem = this.playerData.inventory[i];
                            if(!inventoryItem){
                                throw new Error(`Invalid item: ${i}`);
                            }
                            binWriter.WriteString(inventoryItem.name, "inventory item name");
                            binWriter.WriteInt32(inventoryItem.amount, "inventory item amount");
                            binWriter.WriteSingle(inventoryItem.durability, "inventory item durability");
                            binWriter.WriteInt32(inventoryItem.position.x, "inventory item position x");
                            binWriter.WriteInt32(inventoryItem.position.y, "inventory item position y");
                            binWriter.WriteBoolean(inventoryItem.equipped, "inventory item equipped");
                            binWriter.WriteInt32(inventoryItem.quality, "inventory item quality");
                            binWriter.WriteInt32(inventoryItem.variant, "inventory item variant");

                            if (inventoryItem.crafterId && inventoryItem.crafterName ){
                                binWriter.WriteUInt64(BigInt(inventoryItem.crafterId), "inventory item crafter number");
                                binWriter.WriteString(inventoryItem.crafterName, "inventory item crafter name", true);
                            }else{
                                binWriter.WriteUInt64(0n, "inventory empty item crafter number");
                                binWriter.WriteString("", "inventory empty item crafter name", true);
                            }

                        }
                    }

                    // known
                    binWriter.WriteInt32(this.playerData.knownRecipes.length, "knownRecipesCount");
                    for (let i = 0; i < this.playerData.knownRecipes.length; i++) {
                        binWriter.WriteString(this.playerData.knownRecipes[i]);
                    }

                    binWriter.WriteInt32(Object.keys(this.playerData.knownStations).length, "knownStationsCount");
                    for (const k in this.playerData.knownStations) {
                        binWriter.WriteString(k);
                        binWriter.WriteInt32(this.playerData.knownStations[k]);
                    }

                    binWriter.WriteInt32(this.playerData.knownMaterials.length, "knownMaterialsCount");
                    for (let i = 0; i < this.playerData.knownMaterials.length; i++) {
                        binWriter.WriteString(this.playerData.knownMaterials[i]);
                    }

                    binWriter.WriteInt32(this.playerData.knownTutorials.length, "knownTutorialsCount");
                    for (let i = 0; i < this.playerData.knownTutorials.length; i++) {
                        binWriter.WriteString(this.playerData.knownTutorials[i]);
                    }

                    binWriter.WriteInt32(this.playerData.knownUniques.length, "knownUniquesCount");
                    for (let i = 0; i < this.playerData.knownUniques.length; i++) {
                        binWriter.WriteString(this.playerData.knownUniques[i]);
                    }

                    binWriter.WriteInt32(this.playerData.knownTrophies.length, "knownTrophiesCount");
                    for (let i = 0; i < this.playerData.knownTrophies.length; i++) {
                        binWriter.WriteString(this.playerData.knownTrophies[i]);
                    }

                    binWriter.WriteInt32(this.playerData.knownBiomes.length, "knownBiomesCount");
                    for (let i = 0; i < this.playerData.knownBiomes.length; i++) {
                        binWriter.WriteInt32(this.playerData.knownBiomes[i]);
                    }

                    /*
                    const knownTexts = binWriter.WriteInt32();
                    console.log(`\tKnown Texts: ${knownTexts}`);
                    this.playerData.knownTexts = {};
                    for (let i = 0; i < knownTexts; i++) {
                        const k = binWriter.WriteString();
                        const v = binWriter.WriteString();
                        this.playerData.knownTexts[k] = v;
                        // console.log(`\t\t${binWriter.WriteString()}: ${binWriter.WriteString()}`);
                    }*/
                    binWriter.WriteInt32(Object.keys(this.playerData.knownTexts).length, "knownTextsCount");
                    for (const k in this.playerData.knownTexts) {
                        binWriter.WriteString(k);
                        binWriter.WriteString(this.playerData.knownTexts[k]);
                    }

                    // looks
                    binWriter.WriteString(this.playerData.beard, "beard");
                    //console.log(`\tBeard: ${this.playerData.beard}`);
                    binWriter.WriteString(this.playerData.hair, "hair");
                    //console.log(`\tHair: ${this.playerData.hair}`);
                    
                    binWriter.WriteColour(this.playerData.skinColour, "skin colour");
                    binWriter.WriteColour(this.playerData.hairColour, "hair colour");

                    // console.log(`\tSkin colour: rgb(${Math.round(binWriter.WriteSingle() * 255)},${Math.round(binWriter.WriteSingle() * 255)},${Math.round(binWriter.WriteSingle() * 255)})`);
                    // console.log(`\tHair colour: rgb(${Math.round(binWriter.WriteSingle() * 255)},${Math.round(binWriter.WriteSingle() * 255)},${Math.round(binWriter.WriteSingle() * 255)})`);
                    binWriter.WriteInt32(this.playerData.modelIndex, "modelIndex");
                    //console.log(`\tModel index: ${this.playerData.modelIndex}`);

                    // Food
                    binWriter.WriteInt32(this.playerData.foods.length, "foodCount");
                    for (const food of this.playerData.foods) {
                        binWriter.WriteString(food.name, "food name");
                        binWriter.WriteSingle(food.hp, "food hp");
                        binWriter.WriteSingle(food.stamina, "food stamina");
                        // console.log(`\t\t${binWriter.WriteString()}: \r\n\t\t\tHP: ${binWriter.WriteSingle()}\r\n\t\t\tStamina: ${binWriter.WriteSingle()}`);
                    }

                    // Skills
                    /*
                    console.log(`Skills DataVersion: ${binWriter.WriteInt32()}`);
                    const skillCount = binWriter.WriteInt32();
                    console.log(`\tSkills: ${skillCount}`);
                    this.playerData.skills = [];
                    for (let i = 0; i < skillCount; i++) {
                        const skill = {
                            id: binWriter.WriteInt32(),
                            level: binWriter.WriteSingle(),
                            accumulator: binWriter.WriteSingle()
                        };
                        this.playerData.skills.push(skill);
                        // console.log(`\t\t${playerReader.ReadInt32()}: \r\n\t\t\tLevel: ${playerReader.ReadSingle()}\r\n\t\t\tAccumulator: ${playerReader.ReadSingle()}`);
                    }*/
                    binWriter.WriteInt32(this.playerData.dataVersions.skills, "skillsDataVersion");
                    binWriter.WriteInt32(this.playerData.skills.length, "skillCount");
                    for (const skill of this.playerData.skills) {
                        binWriter.WriteInt32(skill.id, "skill id");
                        binWriter.WriteSingle(skill.level, "skill level");
                        binWriter.WriteSingle(skill.accumulator, "skill accumulator");
                        // console.log(`\t\t${binWriter.WriteString()}: \r\n\t\t\tHP: ${binWriter.WriteSingle()}\r\n\t\t\tStamina: ${binWriter.WriteSingle()}`);
                    }

                const tmpOffset = binWriter.tell();
                binWriter.seek(playerDataOffsetStart, "seek back to playerDataOffset");
                binWriter.WriteInt32(Math.abs(playerDataOffsetStart-tmpOffset)-4, "write playerDataOffset");
                binWriter.seek(tmpOffset, "go back to previous offset from playerDataOffset");

                // playerDataOffsetEnd = binWriter.tell();

                //}

            }

            const lastSaveBuffer = binWriter.tell();
            binWriter.seek(0);
            binWriter.WriteInt32(lastSaveBuffer-4, "last buffer total size");
            // console.log(`Last size: ${lastSaveBuffer} / ${binWriter.buffer.byteLength}`);
            binWriter.seek(lastSaveBuffer, "seek back to end");

            const hash = sha512.arrayBuffer(binWriter.buffer.slice(dataStartOffset, binWriter.tell()));

            binWriter.WriteInt32(this.playerData.hashLength);
            binWriter.WriteBytes(hash);

            const lastBuffer = binWriter.tell();

            const finalBuffer = binWriter.buffer.slice(0, lastBuffer);

            this.isExporting = false;

            return finalBuffer;

        },
        doExportJSON() {
            this.isExporting = true;

            if(this.playerData.worldData) for(const world of this.playerData.worldData){ world.leafletMap.remove(); world.leafletMap = undefined; } // remove map
            
            const blob = new Blob([JSONBig.stringify(this.playerData)], { type: "application/json"});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.playerData.playerName?.toLowerCase()}.json`;
            link.click();
            this.isExporting = false;

            if(this.playerData.worldData) for(let i = 0; i < this.playerData.worldData.length; i++) this.generateMap(i); // add map back again

        },
        makeNewInventoryItem(y: number, x: number, startEditing = false){
            console.log(y, x);
            const inventoryItem: InventoryItem = {
                name: "Torch",
                amount: 1,
                durability: 100,
                position: { x: x, y: y },
                equipped: false,
                quality: 1,
                variant: 0,
                crafterId:  this.playerData.playerId,
                crafterName: this.playerData.playerName || "Stranger",
                // config: findItemConfig

                isEditing: startEditing
            };
            this.playerData.inventory?.push(inventoryItem);
        },
        deleteInventoryItem(y: number, x: number) {
            if(!this.playerData.inventory) return;
            for (let i = 0; i < this.playerData.inventory.length; i++) {
                if (this.playerData.inventory[i].position.y == y && this.playerData.inventory[i].position.x == x){
                    this.playerData.inventory.splice(i, 1);
                }
            }
            // console.log("deleted item, new inventory", this.playerData.inventory);
        },
        pasteInventoryItem(y: number, x: number){ 
            if(!this.playerData.inventory) return;
            const newItem = JSONBigStrict.parse(JSONBigStrict.stringify(this.copyInventoryItem)) as InventoryItem;
            newItem.position = { x: x, y: y };
            this.playerData.inventory.push(newItem);
            this.copyInventoryItem = undefined;
        },
        refillFood(index: number){
            const def = this.itemConfig[this.playerData.foods[index].name];
            if(!def.m_itemData.m_shared.m_food || !def.m_itemData.m_shared.m_foodStamina) return;
            this.playerData.foods[index].hp = def.m_itemData.m_shared.m_food;
            this.playerData.foods[index].stamina = def.m_itemData.m_shared.m_foodStamina;
        },
        deleteFood(index: number){
            this.playerData.foods.splice(index, 1);
        },
        maxSkill(id: number){
            for (let i = 0; i < this.playerData.skills.length; i++) {
                if(this.playerData.skills[i].id == id){
                    this.playerData.skills[i].level = 99;
                    this.playerData.skills[i].accumulator = 99;
                }
            }
        },
        maxAllSkills(){
            for (let i = 0; i < this.playerData.skills.length; i++) {
                this.maxSkill(this.playerData.skills[i].id);
            }
        },
        deleteWorld(index: number){
            if(!this.playerData.worldData || this.playerData.worldData.length == 0) return;
            this.playerData.worldData.splice(index, 1);
        },
        addFood(ev: Event){

            const form = ev.target as HTMLFormElement;
            const inputs = new FormData(form);

            if(this.playerData.foods.length >= 3){
                alert("Too many active foods");
                ev.preventDefault();
                return false;
            }

            const name = inputs.get("name") as string;

            if(this.playerData.foods.some(e => e.name === name)){
                alert("That food is already active.");
                ev.preventDefault();
                return false;
            }

            this.playerData.foods.push({
                name: name,
                hp: this.itemConfig[name].m_itemData.m_shared.m_food,
                stamina: this.itemConfig[name].m_itemData.m_shared.m_foodStamina,
            });

            ev.preventDefault();
            return false;
        },
        generateMap(num: number){
            if(process.env.NODE_ENV === 'test') return;
            if(!this.playerData.worldData) return;
            // const canvas = this.$refs['worldcanvas-' + num] as HTMLCanvasElement;
            const world = this.playerData.worldData[num];
            if(!world) return;

            const canvas = document.createElement("canvas");
            canvas.width = 2048;
            canvas.height = 2048;
            const ctx = canvas.getContext('2d');
            if(!ctx) return;

            world.worldMapRendering = true;

            const textureSize = world.textureSize.valueOf();

            // const canvasSize = 512;
            const scale = textureSize / this.worldCanvasSize;
            console.log(`texture size: ${textureSize}`);
            console.log(`canvas size: ${this.worldCanvasSize}`);
            console.log(`map scale: ${scale}`);
            
            // draw background
            ctx.fillStyle = 'rgb(0, 0, 0)';
            ctx.fillRect(0, 0, this.worldCanvasSize, this.worldCanvasSize);

            // draw world
            ctx.fillStyle = 'rgb(255, 220, 220)';
            ctx.beginPath();
            ctx.arc(this.worldCanvasSize/2, this.worldCanvasSize/2, this.worldCanvasSize/2, 0, 2 * Math.PI);
            ctx.fill();
            ctx.closePath();

            // draw explored
            ctx.fillStyle = 'rgb(0, 0, 0)';
            const exploredString = new RLE().decode(world.exploredString.toString());
            
            const id = ctx.getImageData(0, 0, 1, 1);
            const d = id.data;
            d[0] = 0;
            d[1] = 0;
            d[2] = 0;
            d[3] = 255;
            
            for (let x = 0; x < textureSize; x++) {
                for (let y = 0; y < textureSize; y++) {
                    if( exploredString.charAt((y * textureSize) + x) === 'X' ){
                        const drawY = textureSize - y;
                        ctx.fillRect(x / scale, drawY / scale, 1, 1);
                        // ctx.putImageData(id, x / scale, drawY / scale);
                    }
                }
            }
            
            world.worldMapRendered = true;
            world.worldMapRendering = false;

            this.generateLeaflet(num, canvas.toDataURL());

        },
        generateLeaflet(num: number, imageUrl: string){
            if(!this.playerData.worldData) return;
            const world = this.playerData.worldData[num];
            if(!world) return;

            console.debug("generate leaflet map");

            const leafletMapDiv = document.getElementById(`worldmap_${num}`);
            if(!leafletMapDiv) return;

            if(world.leafletMap) world.leafletMap.remove();

            world.leafletMap = leaflet.map(`worldmap_${num}`, { crs: leaflet.CRS.Simple, minZoom: -2, maxZoom: 12 }).setView([0, 0], 1);
            
            const bounds = leaflet.latLngBounds(leaflet.latLng(-1024, -1024), leaflet.latLng(1024, 1024));
            
            // explored map
            leaflet.imageOverlay(imageUrl, bounds).addTo(world.leafletMap);

            world.leafletMap.fitBounds(bounds);

            this.placeLeafletMarkers(num);

        },
        placeLeafletMarkers(worldNum: number){
            if(!this.playerData.worldData) return;
            const world = this.playerData.worldData[worldNum];
            if(!world) return;

            console.debug("generate leaflet markers");

            const iconBlue = leaflet.icon({
                iconUrl: 'images/marker-icon.png',
                shadowUrl: 'images/marker-shadow.png',
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize:  [41, 41]
            });

            const iconRed = leaflet.icon({
                iconUrl: 'images/marker-icon-red.png',
                shadowUrl: 'images/marker-shadow.png',
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize:  [41, 41]
            });

            const iconGreen = leaflet.icon({
                iconUrl: 'images/marker-icon-green.png',
                shadowUrl: 'images/marker-shadow.png',
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize:  [41, 41]
            });

            const iconYellow = leaflet.icon({
                iconUrl: 'images/marker-icon-yellow.png',
                shadowUrl: 'images/marker-shadow.png',
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize:  [41, 41]
            });

            // remove old markers
            world.leafletMap.eachLayer((layer: leaflet.Layer) => {
                if((layer as any)._image) return; // don't remove overlay
                layer.remove();
            });

            const calcPos = (x: number, y: number) => {
                const textureSize = world.textureSize;
            
                const worldScale = ( 25_000 / textureSize );

                // const x = ( pos.x / worldScale ) + ( this.worldCanvasSize / 2 );
                // const y = ( ( pos.z * -1 ) / worldScale ) + ( this.worldCanvasSize / 2 );
                const s = 2048 / this.worldCanvasSize;

                return {
                    x: ( x / worldScale / s ),
                    y:  ( ( y ) / worldScale / s )
                };
            }

            const englishPinNames: Record<string, string> = {
                '$enemy_eikthyr': 'Eikthyr',
                '$enemy_gdking': 'The Elder',
                '$enemy_dragon': 'Moder',
            };

            for(const pin of world.pins){
                // const x = pin.position.x / 25000;
                // const y = (pin.position.z * -1) / 25000;
                const pos = calcPos(pin.position.x, pin.position.z);
                
                const marker = leaflet.marker(
                    [pos.y, pos.x], 
                    {
                        title: pin.name,
                        icon: pin.type == 9 ? iconYellow : iconBlue,
                        // draggable: true
                    })
                    .addTo(world.leafletMap);
                
                marker.bindPopup(`<strong>${englishPinNames[pin.name] || pin.name}</strong><br>${this.pinIconNames[pin.type]} (${pin.type})`);
                /*
                marker.addEventListener('moveend', (event: Event) => {
                    console.log("drag end", pin.name, event);
                });
                */
            }
            
            const homePos = calcPos(world.homePoint.x, world.homePoint.z);
            const homeMarker = leaflet.marker([homePos.y, homePos.x], { title: "Home", icon: iconGreen }).addTo(world.leafletMap);
            homeMarker.bindPopup("<strong>Home</strong>");

            if(world.hasDeathPoint){
                const deathPos = calcPos(world.deathPoint.x, world.deathPoint.z);
                const deathMarker = leaflet.marker([deathPos.y, deathPos.x], { title: "Death", icon: iconRed }).addTo(world.leafletMap);
                deathMarker.bindPopup("<strong>Death</strong>");
            }

            if(world.hasLogoutPoint){
                const logoutPos = calcPos(world.logoutPoint.x, world.logoutPoint.z);
                const logoutMarker = leaflet.marker([logoutPos.y, logoutPos.x], { title: "Logout", icon: iconGreen }).addTo(world.leafletMap);
                logoutMarker.bindPopup("<strong>Logout</strong>");
            }

            console.debug("leaflet map markers generated");

        },
        exploreFullMap(num: number){
            if(!this.playerData.worldData) return;
            const world = this.playerData.worldData[num];
            if(!world) return;
            /*
            for (let x = 0; x < world.textureSize; x++) {
                for (let y = 0; y < world.textureSize; y++) {
                    world.exploredGrid[x][y] = true;
                }
            }
            */
            // world.exploredString = new RLE().encode(pogo);
            // let fullMap = "OOO";
            // fullMap += "X".repeat( (world.textureSize*world.textureSize) - 3);
            // 
            // world.exploredString = new RLE().encode(fullMap);
            world.exploredString = `{${world.textureSize*world.textureSize}}X`;
            // console.log(world.exploredString);
            this.generateMap(num);
        },
        worldCanvasPinStyle(pos: Vector3){
            
            const textureSize = ( this.playerData.worldData ? this.playerData.worldData[0].textureSize : 2048 );
            
            const worldScale = ( 25_000 / textureSize );

            // const x = ( pos.x / worldScale ) + ( this.worldCanvasSize / 2 );
            // const y = ( ( pos.z * -1 ) / worldScale ) + ( this.worldCanvasSize / 2 );
            const s = 2048 / this.worldCanvasSize;

            const x = ( pos.x / worldScale / s ) + ( this.worldCanvasSize / 2 );
            const y = ( ( pos.z * -1 ) / worldScale / s ) + ( this.worldCanvasSize / 2 );

            return {
                top: `${y}px`,
                left: `${x}px`
            }
        },
        inventoryManage(ev: SubmitEvent){
            const form = ev.target as HTMLFormElement;
            const inputs = new FormData(form);

            const method = ev.submitter.value;
            const slot = inputs.get("slot") as string;

            console.log(method, slot);

            if( method == "export" ){
                const copy = Object.assign({}, this.playerData.inventory);
                this.inventoryBackups[slot] = copy;
                console.log("backups", this.inventoryBackups);
            }else if( method == "import" ){
                if(!this.inventoryBackups[slot]){
                    alert("No such slot");
                    ev.preventDefault();
                    return false;
                }
                console.log("import slot", this.inventoryBackups[slot]);
                // this.playerData.inventory = {...this.inventoryBackups[slot]};
                this.playerData.inventory = Object.assign({}, this.inventoryBackups[slot]);
            }

            ev.preventDefault();
            return false;
        },
        doBackup(data: PlayerData){
            
            const backups = localStorage.playerDataBackups ? JSONBig.parse(localStorage.playerDataBackups) : [];
            
            const crc = crc32(JSONBig.stringify(data));
            
            for(const oldBackup of backups){
                if(oldBackup.crc === crc){
                    console.log("already has backup", crc);
                    return;
                }
            }

            backups.push({
                name: data.playerName,
                crc: crc,
                data: data
            });

            console.log("backed up", crc);

            try {
                localStorage.playerDataBackups = JSONBig.stringify(backups);
            } catch (error) {
                console.error("couldn't back up", crc, error);
            }
            
        }
    },
    computed: {
        /**
         * Output a full inventory with x/y and empty slots
         */
        formattedInventory(): InventoryItem[][] | InventoryItemEmpty[][] {
            const inv = [];
            for(let y = 0; y < 4; y++){
                const row = [];
                for(let x = 0; x < 8; x++){
                    let found = false;
                    if(this.playerData.inventory){
                        // console.log(this.playerData.inventory);
                        for(const tmpItem of this.playerData.inventory){
                            if (tmpItem && tmpItem.position && tmpItem.position.x == x && tmpItem.position.y == y){
                                row.push(tmpItem);
                                found = true;
                                break;
                            }
                        }
                    }
                    if(!found){
                        row.push({ empty: true });
                    }
                    
                }
                inv.push(row);
            }
            return inv;
        },
        hairColour: {
            get(): string {
                return rgbToHex(Math.round(this.playerData.hairColour.r), Math.round(this.playerData.hairColour.g), Math.round(this.playerData.hairColour.b));
            },
            set(val: string): void {
                this.playerData.hairColour = hexToRgb(val);
            }
        },
        skinColour: {
            get(): string {
                return rgbToHex(Math.round(this.playerData.skinColour.r), Math.round(this.playerData.skinColour.g), Math.round(this.playerData.skinColour.b));
            },
            set(val: string): void {
                this.playerData.skinColour = hexToRgb(val);
            }
        },
        sortedItems(): MItem[] {
            return this.gameObjects
                .slice() // clone array
                .sort((a, b) => a.m_itemData.m_shared.m_name_EN.localeCompare(b.m_itemData.m_shared.m_name_EN));
        },
        pickableInventoryItemsSorted(): { value: string; label: string; data: MItem }[] {
            // const ignoredTypes = ['Customization'];
            /*
            return this.sortedItems
                .filter((item) => ignoredTypes.indexOf(item.m_itemData.m_shared.m_itemType) === -1 );
                */
            const data = [];
            for(const i in this.sortedItems){
                const item = this.sortedItems[i];
                data.push({
                    value: item.name,
                    label: item.m_itemData.m_shared.m_name_EN,
                    data: item
                });
            }
            return data;
        },
        foodItems(): MItem[] {
            return this.gameObjects
                .filter(a => a.m_itemData.m_shared.m_food > 0 )
                // .filter(a => a.m_itemData.m_shared.m_itemType === "Consumable" )
                .slice() // clone array
                .sort((a, b) => a.m_itemData.m_shared.m_name_EN.localeCompare(b.m_itemData.m_shared.m_name_EN));
        },
        inventoryWeight(): number {
            if(!this.playerData.inventory) return 0;
            return this.playerData.inventory.reduce(
                (a, b) => a + this.itemConfig[b.name].m_itemData.m_shared.m_weight * b.amount,
                0
            );
        },
        inventoryArmor(): number {
            if(!this.playerData.inventory || this.playerData.inventory.length == 0) return 0;
            let total = 0;
            const eligible = ["Legs", "Chest", "Shoulder"]
            for (const item of this.playerData.inventory){
                if( item === undefined ){ console.error("undefined item"); continue; }
                if (
                    item.equipped &&
                    this.itemConfig[item.name].m_itemData.m_shared.m_armor &&
                    eligible.indexOf(this.itemConfig[item.name].m_itemData.m_shared.m_itemType) !== -1
                ){
                    total += this.itemConfig[item.name].m_itemData.m_shared.m_armor;
                }
            }
            return total;
        }
    }
});
