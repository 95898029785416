import { Colour, Vector2, Vector3 } from "@/valheim";

export default class BinaryReader {
    
    buffer: ArrayBufferLike;
    dataview: DataView;
    pointer: number;
    debug: boolean;

    constructor (buffer: ArrayBuffer){
        this.pointer = 0;
        this.buffer = buffer;
        this.dataview = new DataView(this.buffer);
        this.debug = process.env.NODE_ENV === 'development';
    }

    ReadInt64(what?: string) {
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read int64 (${what})`);
        const val = this.dataview.getBigInt64(this.pointer, true);
        this.pointer += 8;
        return val;        
    }

    ReadUInt64(what?: string): bigint {
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read uint64 (${what})`);
        const val = this.dataview.getBigUint64(this.pointer, true);
        this.pointer += 8;
        return val;            
    }

    ReadInt32(what?: string) {
        const val = this.dataview.getInt32(this.pointer, true);
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read int32 (${what}): ${val}`);
        this.pointer += 4;
        return val;
    }

    ReadUint32() {
        const val = this.dataview.getUint32(this.pointer, true);
        this.pointer += 4;
        return val;         
    }

    ReadInt16() {
        const val = this.dataview.getInt16(this.pointer, true);
        this.pointer += 2;
        return val;         
    }

    ReadUint16() {
        const val = this.dataview.getInt16(this.pointer, true);
        this.pointer += 2;
        return val;          
    }
    
    ReadInt8() {
        const val = this.dataview.getInt8(this.pointer);
        this.pointer += 1;
        return val;         
    }

    ReadUint8() {
        const val = this.dataview.getUint8(this.pointer);
        this.pointer += 1;
        return val;          
    }

    ReadByte() {
        const a = this.ReadUint8();
        if(a){
            this.pointer += 1;
            return String.fromCharCode(a);
        }          
    }

    ReadBytes(len: number) {
        // console.debug(`# Start read bytes at ${this.pointer}/${this.dataview.byteLength} with length ${len}`);
        const a = this.buffer.slice(this.pointer, this.pointer + len);
        if(a){
            this.pointer += len;
            return a;
        }          
    }

    ReadByteArray() {
        return this.ReadBytes(this.ReadInt32());
    }

    ReadSingle(what?: string) {
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read single (${what})`);
        const val = this.dataview.getFloat32(this.pointer, true);
        this.pointer += 4;
        return val;        
    }

    ReadVector2(what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read vector2 (${what})`);
        return {
            y: this.ReadSingle(),
            x: this.ReadSingle()
        };
    }

    ReadVector3(what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read vector3 (${what})`);
        return {
            x: this.ReadSingle(),
            y: this.ReadSingle(),
            z: this.ReadSingle()
        };
    }

    ReadColour(what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read colour (${what})`);
        return {
            r: this.ReadSingle() * 255,
            g: this.ReadSingle() * 255,
            b: this.ReadSingle() * 255,
        };
    }

    ReadBoolean(what?: string) {
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read boolean (${what})`);
        const val = this.dataview.getUint8(this.pointer);
        this.pointer += 1;
        return val > 0;          
    }

    ReadString(what?: string, utf8 = false) {
        if (what && this.debug) console.debug(`$ [${this.pointer}] Read string (${what})`);
        const strLen = this.ReadInt8();
        if (what && this.debug) console.debug(`\tLength: ${strLen}`);
        let finalString = "";
        for(let i = 0; i < strLen; i++){
            const byte = this.dataview.getUint8(this.pointer);
            this.pointer += 1;
            finalString += String.fromCharCode(byte);
            if (what && this.debug) console.debug(`$ [${this.pointer}] Read string [${byte}][${String.fromCharCode(byte)}] (${what})`);
        }
        if (utf8){
            finalString = decodeURIComponent(escape(finalString));
        }
        if (what && this.debug) console.debug(`\tFinal string: ${finalString}`);
        return finalString;  
    }


    WriteInt64(val: bigint, what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Write int64 ${val} (${what})`);
        this.dataview.setBigInt64(this.pointer, val, true);
        this.pointer += 8;
    }

    WriteUInt64(val: bigint, what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Write uint64 ${val} (${what})`);
        this.dataview.setBigUint64(this.pointer, val, true);
        this.pointer += 8;
    }

    WriteInt32(val: number, what?: string){
        if(what && this.debug) console.debug(`$ [${this.pointer}] Write int32 ${val} (${what})`);
        this.dataview.setInt32(this.pointer, val, true);
        this.pointer += 4;
    }

    WriteBoolean(val: boolean, what?: string){
        if(what && this.debug) console.debug(`$ [${this.pointer}] Write bool ${val} (${what})`);
        this.dataview.setUint8(this.pointer, val ? 1 : 0);
        this.pointer += 1;
    }

    WriteSingle(val: number, what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Write single ${val} (${what})`);
        this.dataview.setFloat32(this.pointer, val, true);
        this.pointer += 4;
    }

    WriteVector3(val: Vector3, what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Write vector3 ${val} (${what})`);
        this.WriteSingle(val.x);
        this.WriteSingle(val.y);
        this.WriteSingle(val.z);
    }

    WriteVector2(val: Vector2, what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Write vector2 (${val.x}, ${val.y}) (${what})`);
        this.WriteSingle(val.x);
        this.WriteSingle(val.y);
    }

    WriteColour(val: Colour, what?: string){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Write colour ${val} (${what})`);
        this.WriteSingle(val.r / 255);
        this.WriteSingle(val.g / 255);
        this.WriteSingle(val.b / 255);
    }

    WriteString(str: string, what?: string, utf8 = false){
        if (what && this.debug) console.debug(`$ [${this.pointer}] Write string "${str}" (${what})`);

        if(utf8){
            str = unescape(encodeURIComponent(str));
        }

        this.dataview.setInt8(this.pointer, str.length);
        this.pointer += 1;
        for(const letter of str.split("")){
            const cp = letter.codePointAt(0);
            if(cp){
                this.dataview.setInt8(this.pointer, cp);
                this.pointer += 1;
            }
        }
    }

    WriteBytes(bytes: ArrayBuffer){
        const tmp = new DataView(bytes);
        for(let i = 0; i < bytes.byteLength; i++){
            this.dataview.setInt8(this.pointer, tmp.getInt8(i));
            this.pointer += 1;
        }
    }

    seek(num: number, why?: string){
        if(why && this.debug) console.debug(`$ [${this.pointer}] Seek to ${num} (${why})`);
        this.pointer = num;
    }

    skip(num: number, why?: string){
        if(why && this.debug) console.debug(`$ [${this.pointer}] Skip ${num} bytes to ${this.pointer+num} (${why})`);
        this.pointer += num;
    }

    tell(){
        return this.pointer;
    }

}