export default class RLE {

    encode(input: string) {
        return input.replace(/(.)\1*/g, function (m, p1) { return '{' + m.length + '}' + p1 });
    }

    decode(input: string) {
        const charArray = input.split(/{(\d+)}/);
        charArray.shift()
        let decompressed = "";
        for (let i = 0, iLen = charArray.length; i < iLen; i++) {
            const cChar = charArray[i];
            if (i % 2 == 0) continue;
            const numChar = parseInt(charArray[i - 1]);

            for (let c = 1; c <= numChar; c++) {
                decompressed += cChar;
            }
        }
        return decompressed;
    }

}